import styles from "./styles.module.scss";
interface IProps {
  icon: string;
  title: string;
}
function BenefitItem({ icon, title }: IProps) {
  return (
    <div className={styles.block}>
      <div className={styles.iconContainer}>
        <img src={icon} alt="icon" className={styles.icon} />
      </div>
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
}

export default BenefitItem;
