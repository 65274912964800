import { FC, useEffect, useRef, useState } from "react";
import Talk from "talkjs";
import { api, ApiPaths } from "../../api";
import avatar from "./../../../assets/chatbot.png";
import { useParams } from "react-router-dom";

const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

const BotAvatars = {
  jack: "https://res.cloudinary.com/the-coach/image/upload/v1732038966/bmx1mrceerh3lu8jsknj.jpg",
  adrian:
    "https://res.cloudinary.com/hmmgzmwsv/image/upload/v1743004644/pgkvzip5xqtclwo07mvd.jpg",
  marie:
    "https://res.cloudinary.com/hmmgzmwsv/image/upload/v1743094046/pojskaczvgld7nkvqqiu.png",
  alice: avatar,
  bot: avatar,
};

const BotNames = {
  jack: "The Coach AI",
  adrian: "Adrian",
  marie: "Marie",
  alice: "AI Girlfriend",
  bot: "The Coach AI",
};

const ChatBot: FC = () => {
  const chatboxRef = useRef<HTMLDivElement>(null);
  const talkSessionRef = useRef<Talk.Session | null>(null);
  const conversation = useRef<Talk.ConversationBuilder | null>(null);
  const [conversationId, setCoversationId] = useState<null | string>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get("user_id") || queryParams.get("userid");
  const { bot_id } = useParams();
  const botId = (bot_id || "bot") as keyof typeof BotAvatars;

  const bot_avatar = BotAvatars[botId];

  useEffect(() => {
    Talk.ready.then(() => {
      // Define user and bot users
      const user = new Talk.User({
        id: userId || guidGenerator(),
        name: "User",
        email: "user@example.com",
        photoUrl: "https://example.com/user-avatar.jpg",
      });

      const bot = new Talk.User({
        id: botId,
        name: BotNames[botId],
        email: "support@the.coach",
        photoUrl: bot_avatar,
      });

      // Initialize TalkJS session
      if (!talkSessionRef.current) {
        talkSessionRef.current = new Talk.Session({
          appId: "tnq8uuyi",
          me: user,
        });
      }

      // Create a conversation and add participants
      conversation.current = talkSessionRef.current.getOrCreateConversation(
        Talk.oneOnOneId(user.id, bot.id)
      );
      conversation.current.setParticipant(user);
      conversation.current.setParticipant(bot);

      //@ts-ignore
      setCoversationId(conversation.current.id);

      // Initialize and mount the chatbox
      const chatbox = talkSessionRef.current.createChatbox();
      chatbox.select(conversation.current);

      if (chatboxRef.current) {
        chatbox.mount(chatboxRef.current);
      }

      talkSessionRef.current?.onMessage(async (message) => {
        if (message.custom.showPaywall === "show") {
          // @ts-ignore
          const isAppleWebview = window.webkit && window.webkit.messageHandlers;

          if (isAppleWebview) {
            // @ts-ignore
            webkit.messageHandlers.callbackHandler.postMessage("show_paywall");
            return;
          }

          // @ts-ignore
          const isAndroidWebview = window.callbackHandler;

          if (isAndroidWebview) {
            // @ts-ignore
            window.callbackHandler.postMessage("show_paywall");
            return;
          }
        }

        if (conversation.current && message.senderId !== bot.id) {
          try {
            await api.post(
              ApiPaths.CHAT_BOT,
              JSON.stringify({
                message: message,
                bot: bot,
                bot_id: bot.id,
                talk_user: user,
                user_id: userId,
              })
            );
          } catch (e) {
            console.error(e);
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversationId) {
      (async () => {
        try {
          await api.post(
            ApiPaths.CHAT_BOT_START,
            JSON.stringify({
              conversation_id: conversationId,
              bot_id: botId,
              user_id: userId,
            })
          );
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  return (
    <div
      id="talkjs-container"
      ref={chatboxRef}
      style={{ height: "100dvh" }}
    ></div>
  );
};

export default ChatBot;
