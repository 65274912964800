import styles from "./../components/BraintreeCard/styles.module.scss";
import { validateEmail } from "./cardValidation";

export const createEmailInput = (
  isEmailValid: React.MutableRefObject<string | null>
) => {
  const emailValue = localStorage.getItem("payment_email") || "";

  const container = document.createElement("div");
  container.classList.add("braintree-form__flexible-fields");
  container.innerHTML = `
          <div class="braintree-form__field-group">
            <label>
              <div class="braintree-form__label">Email</div>
              <div class="braintree-form__field">
               <div class="braintree-form__hosted-field">
                  <input 
                    class="${styles["braintree-custom-input"]}" 
                    name="user-email" 
                    type="text" 
                    placeholder="EMAIL"
                    value="${emailValue}"
                  />
                </div>
              </div>
            </label>
            <div id="emailError" class="braintree-form__field-error"></div>
          </div>`;

  const host = document.querySelector(
    '[data-braintree-id="card"] .braintree-sheet__content--form'
  );

  if (host) {
    host.appendChild(container);
    const input = container.querySelector('[name="user-email"]');
    input?.addEventListener("change", (event: Event) => {
      const input = event.target as HTMLInputElement;
      localStorage.setItem("payment_email", input.value);
      const isValid = validateEmail(input.value);

      setEmailError(!!isValid, isEmailValid);
    });
  }

  const isValid = validateEmail(emailValue);
  setEmailError(!!isValid, isEmailValid);
};

export const setEmailError = (
  isValid: boolean,
  isEmailValid: React.MutableRefObject<string | null>
) => {
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");

  const errorDiv = document.getElementById("emailError");
  const input = document.querySelector('[name="user-email"]');
  if (!input) return;

  const hostedFieldContainer = input.closest(
    ".braintree-form__hosted-field"
  ) as HTMLElement;

  const errorMsg =
    lng === "es" ? "Correo electrónico no válido" : "Invalid email";
  if (isValid) {
    isEmailValid.current = null;
  } else {
    isEmailValid.current = errorMsg;
  }

  if (errorDiv) {
    errorDiv.textContent = isValid ? "" : errorMsg;
    errorDiv.style.display = isValid ? "none" : "block";
  }

  if (hostedFieldContainer) {
    hostedFieldContainer.style.borderColor = isValid ? "initial" : "#CA2A2A";
  }
};
