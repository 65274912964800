import { IQuestionnaireData } from ".";
import yearOld18 from "./../../../assets/woman_flow/18-24.jpg";
import yearOld25 from "./../../../assets/woman_flow/25-34.jpg";
import yearOld35 from "./../../../assets/woman_flow/35-44.jpg";
import yearOld45 from "./../../../assets/woman_flow/45+.jpg";
import step2 from "./../../../assets/woman_flow/Step2.jpg";
import step6 from "./../../../assets/woman_flow/Step6.jpg";
import step10 from "./../../../assets/woman_flow/Step10.jpg";
import step14 from "./../../../assets/woman_flow/Step14.jpg";
import step22 from "./../../../assets/woman_flow/Step22.jpg";

export const woman_flow: IQuestionnaireData[] = [
  {
    type: "single-square",
    badge: "WELCOME",
    title: "IT’S TIME TO COME FIRST",
    description: "Choose your age to start:",
    questions: [
      {
        value: "q1",
        text: "18-25",
        img: yearOld18,
      },
      {
        value: "q2",
        text: "26-35",
        img: yearOld25,
      },
      {
        value: "q3",
        text: "36-45",
        img: yearOld35,
      },
      {
        value: "q4",
        text: "46+",
        img: yearOld45,
      },
    ],
  },
  {
    type: "information-custom",
    badge: "JOIN US",
    title: "OVER 1M PEOPLE HAVE ALREADY USED OUR PROGRAM",
    description: "",
    Component: () => (
      <>
        <img
          style={{
            width: "100%",
          }}
          src={step2}
          alt="text"
        />
      </>
    ),
  },
  {
    type: "single",
    badge: "Emotional and Psychological State",
    title: "How would you describe your emotional state over the past month?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Happy and content",
      },
      {
        value: "q2",
        text: "Slightly stressed",
      },
      {
        value: "q3",
        text: "Moderately stressed",
      },
      {
        value: "q4",
        text: "Very stressed",
      },
      {
        value: "q5",
        text: "Overwhelmed",
      },
    ],
  },
  {
    type: "single",
    badge: "Emotional and Psychological State",
    title: "How often do you feel emotionally connected to your partner?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Emotional and Psychological State",
    title: "How do you feel about discussing your sexual desires and needs?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very comfortable",
      },
      {
        value: "q2",
        text: "Comfortable",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Uncomfortable",
      },
      {
        value: "q5",
        text: "Very uncomfortable",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Thanks for sharing with us",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          We know how hard it can be.
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Your mental health plays a key role in your sexual desire. Taking care
          of it is essential for a fulfilling sex life.
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Just remember - you’re doing something important for yourself, and
          that’s truly inspiring.
          <br /> Keep going—we’re here to support you 💜
        </span>
        <img
          style={{
            width: "100%",
            marginTop: "12px",
          }}
          src={step6}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Physical Health and Well-being",
    title: "How would you rate your overall physical health?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Excellent",
      },
      {
        value: "q2",
        text: "Good",
      },
      {
        value: "q3",
        text: "Fair",
      },
      {
        value: "q4",
        text: "Poor",
      },
      {
        value: "q5",
        text: "Very poor",
      },
    ],
  },
  {
    type: "single",
    badge: "Physical Health and Well-being",
    title: "Do you experience pain or discomfort during intercourse?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Physical Health and Well-being",
    title: "Have you ever tried Kegel exercises?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes",
      },
      {
        value: "q2",
        text: "No",
      },
      {
        value: "q3",
        text: "I don’t know what it is",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Never neglect physical activity",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          8/10 women admit a positive impact on their libido after adding
          physical activity in their daily routine
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Regular exercising improves blood circulation, boosts energy levels,
          and enhances mood, which in turn increases sexual desire.
        </span>
        <img
          style={{
            width: "100%",
            marginTop: "12px",
          }}
          src={step10}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Sexual Desire and Patterns",
    title: "How would you rate your sexual desire in general?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very strong",
      },
      {
        value: "q2",
        text: "Strong",
      },
      {
        value: "q3",
        text: "Average",
      },
      {
        value: "q4",
        text: "Low",
      },
      {
        value: "q5",
        text: "Very low",
      },
    ],
  },
  {
    type: "single",
    badge: "Sexual Desire and Patterns",
    title: "Do you feel spontaneous or responsive sexual desire more often?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Mostly spontaneous",
      },
      {
        value: "q2",
        text: "Mostly responsive",
      },
      {
        value: "q3",
        text: "A mix of both",
      },
      {
        value: "q4",
        text: "Unsure",
      },
      {
        value: "q5",
        text: "Neither",
      },
    ],
  },
  {
    type: "single",
    badge: "Sexual Desire and Patterns",
    title:
      "How often do you feel a lack of sexual desire when you want to experience it?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Strengthen your connection",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Good emotional connection leads to good intimate connection. It’s so
          simple that everybody forgets about it.
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Open communication and emotional closeness with your partner can
          enhance libido, while unresolved conflicts may suppress it.
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          That's why the ability to solve conflicts is crucial for good
          relationships.
        </span>
        <img
          style={{
            width: "100%",
            marginTop: "12px",
          }}
          src={step14}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Influences on Desire",
    title:
      "How do life stressors (e.g., work, family) affect your sexual desire?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Significantly decrease it",
      },
      {
        value: "q2",
        text: "Slightly decrease it",
      },
      {
        value: "q3",
        text: "No effect",
      },
      {
        value: "q4",
        text: "Slightly increase it",
      },
      {
        value: "q5",
        text: "Significantly increase it",
      },
    ],
  },
  {
    type: "single",
    badge: "Influences on Desire",
    title:
      "How do conflicts or arguments with your partner impact your desire?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Negative, it decreases my drive",
      },
      {
        value: "q2",
        text: "Neutral",
      },
      {
        value: "q3",
        text: "Positive, I like to add some spices into relationships",
      },
    ],
  },
  {
    type: "single",
    badge: "Influences on Desire",
    title:
      "How does your lifestyle (e.g., exercise, sleep) influence your sexual desire?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very positively",
      },
      {
        value: "q2",
        text: "Positively",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Negatively",
      },
      {
        value: "q5",
        text: "Very negatively",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "You Are Not Alone",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Many women{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            face challenges
          </b>{" "}
          with sexual desire due to life stress, conflicts, or lifestyle
          factors. It’s important to know that these struggles are normal and
          experienced by millions.
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          The good news is that there are{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            proven ways to overcome
          </b>{" "}
          these challenges. By understanding the factors influencing your desire
          and addressing them{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            step by step,{" "}
          </b>
          you’re already{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            taking control.
          </b>
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          This journey isn’t about perfection—it’s about progress. And from us,
          you’ll receive care and guidance every step of the way 💜
        </span>
      </div>
    ),
  },
  {
    type: "single",
    badge: "Self-Perception and Confidence",
    title: "How do you feel about your body?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very confident",
      },
      {
        value: "q2",
        text: "Confident",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Insecure",
      },
      {
        value: "q5",
        text: "Very insecure",
      },
    ],
  },
  {
    type: "single",
    badge: "Self-Perception and Confidence",
    title: "How would you describe your sexual confidence?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very confident",
      },
      {
        value: "q2",
        text: "Confident",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Insecure",
      },
      {
        value: "q5",
        text: "Very insecure",
      },
    ],
  },
  {
    type: "single",
    badge: "Self-Perception and Confidence",
    title: "Do you feel your partner finds you sexually attractive?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Definitely",
      },
      {
        value: "q2",
        text: "Probably",
      },
      {
        value: "q3",
        text: "Not sure",
      },
      {
        value: "q4",
        text: "Probably not",
      },
      {
        value: "q5",
        text: "Definitely not",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "You Deserve to Feel Amazing About Yourself",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          It’s okay if you don’t always feel confident—it’s something many women
          face.{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            Your body is unique, beautiful, and capable 🌸{" "}
          </b>
          <br /> By embracing it and{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            understanding your needs,
          </b>{" "}
          you’re on the path to{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            becoming your most confident
          </b>{" "}
          self. Let’s keep moving forward together—you’re doing great! 💖👏
        </span>
        <img
          style={{
            width: "100%",
            marginTop: "12px",
          }}
          src={step22}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Self-Perception and Confidence",
    title: "How often do you explore new experiences in your intimate life?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very often",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Occasionally",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Self-Perception and Confidence",
    title: "What type of intimacy do you find most fulfilling?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Emotional connection",
      },
      {
        value: "q2",
        text: "Physical pleasure",
      },
      {
        value: "q3",
        text: "Novelty and adventure",
      },
      {
        value: "q4",
        text: "Slow and sensual",
      },
      {
        value: "q5",
        text: "Spontaneous and passionate",
      },
    ],
  },
  {
    type: "single",
    badge: "Self-Perception and Confidence",
    title:
      "Do you know the factors that increase or decrease your sexual desire?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes, very well",
      },
      {
        value: "q2",
        text: "Mostly",
      },
      {
        value: "q3",
        text: "A little",
      },
      {
        value: "q4",
        text: "Not much",
      },
      {
        value: "q5",
        text: "Not at all",
      },
    ],
  },
  {
    type: "analyze",
    badge: "",
    title: "",
    description: "",
    Component: () => <></>,
  },
  {
    type: "single",
    badge: "Sexual Preferences and Behaviors",
    title: "How important is improving your sexual desire and intimacy to you?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very important",
      },
      {
        value: "q2",
        text: "Important",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Not very important",
      },
      {
        value: "q5",
        text: "Not important at all",
      },
    ],
  },
  {
    type: "single",
    badge: "Sexual Preferences and Behaviors",
    title:
      "What is the main goal you would like to achieve by exploring your sexual desire?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Greater connection with my partner",
      },
      {
        value: "q2",
        text: "Better understanding of myself",
      },
      {
        value: "q3",
        text: "More confidence and control",
      },
      {
        value: "q4",
        text: "Enhanced physical pleasure",
      },
      {
        value: "q5",
        text: "Unsure",
      },
    ],
  },
  // {
  //   type: "email",
  //   badge: "",
  //   title:
  //     "Enter your email to save your profile and improve your intimate life",
  //   description: "",
  //   Component: () => <></>,
  // },
];
