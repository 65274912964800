import { FC } from "react";

import Title from "../Title";
import Button from "../Button";
import { ReactComponent as Checked } from "./../../../assets/svg/paymentUnsuccess.svg";

import styles from "./styles.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface IProps {
  setIsPaymentFailed: React.Dispatch<React.SetStateAction<boolean>>;
  isPaymentFailed: boolean;
}

const PaymentFailed: FC<IProps> = ({ setIsPaymentFailed, isPaymentFailed }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.active]: isPaymentFailed,
      })}
    >
      <div className={styles.container}>
        <Checked width={"127px"} height={"62px"} />
        <Title text={t("paywall_payment_unsuccesfull")} fontSize={40} />
        <p className={styles.tryAgian}>
          {t("paywall_payment_smth_went_Wrong")}
        </p>
        <p className={styles.tryDifferent}>
          {t("paywall_payment_unsuccesfull_desc")}
        </p>

        <Button
          buttonVariants="secondary"
          handleClick={() => setIsPaymentFailed(false)}
          text={t("paywall_payment_unsuccesfull_diff_method") || ""}
        />
        <span className={styles.or}>
          {t("paywall_payment_unsuccesfull_or")}
        </span>

        <div className={styles.problems}>
          <p>{t("paywall_payment_unsuccesfull_note")}</p>
          <div className={styles.osBtns}>
            <img
              width="180px"
              height="60px"
              src="https://liga.the.coach/img/content/paywall/app-store-apple.png"
              alt="google-play"
            />
            <img
              width="180px"
              height="60px"
              src="https://liga.the.coach/img/content/paywall/app-store-google.png"
              alt="app-store"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
