import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import es from "./locales/es.json";

const resources = {
  en: { translation: en },
  es: { translation: es },
} as const;

const queryParams = new URLSearchParams(window.location.search);
const lng = queryParams.get("lng");

i18n
  // .use(LanguageDetector) // Detects browser language
  .use(initReactI18next) // Connects i18next with React
  .init({
    lng: lng as string,
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: true,
  });

export default i18n;
export type TranslationKeys = keyof typeof en;
