import { FC } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import progress_1_img from "./../../../assets/cancel/progress_1.png";
import progress_1_img_es from "./../../../assets/cancel/progress_1_es.png";
import progress_2_img from "./../../../assets/cancel/progress_2.png";
import Icon, { IconTypes } from "../Icon";
import { useTranslation } from "react-i18next";

interface ICancelStepSavings {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
  contactUs: (theme: string) => void;
}

const CancelStepProgress: FC<ICancelStepSavings> = ({
  onNextStep,
  onBackToApp,
  contactUs,
}) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");
  const isES = lng === "es";

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.close} onClick={onBackToApp}>
          <Icon type={IconTypes.CROSS} size={12} />
        </div>
      </div>
      <div className={styles.logo}></div>
      <div className={styles.title}>{t("cancel_flow_users_see_results")}</div>
      <div className={styles.description}>
        {t("cancel_flow_results_variation")}
      </div>

      <img
        alt="saving1_img"
        src={isES ? progress_1_img_es : progress_1_img}
        className={styles.img}
      />
      <div className={styles.description}>
        {t("cancel_flow_consistency_key")}
      </div>
      <img alt="saving2_img" src={progress_2_img} className={styles.img} />
      <div className={styles.sub_title}>{t("cancel_flow_need_help")}</div>
      <div className={styles.description}>
        {t("cancel_flow_support_message_1")}{" "}
        <span className={styles.link} onClick={() => contactUs("Need+Help")}>
          {t("cancel_flow_support_message_2")}
          {""}
        </span>
        {t("cancel_flow_support_message_3")}
      </div>
      <div className={styles.actions}>
        <button className={styles.buttonStay} onClick={onBackToApp}>
          {t("cancel_flow_try_again")}
        </button>
        <button
          className={styles.buttonProceed}
          onClick={() => onNextStep(CANCEL_STEPS.REASON)}
        >
          {t("cancel_flow_cancel_subscription")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepProgress;
