import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import braintree, { Client } from "braintree-web";
import { ApiPaths, api } from "../../api";
import { IPlan } from "../../hooks/usePlans";
import useToken from "../../hooks/useToken";
import { ReactComponent as CredtitCard } from "./../../../assets/svg/credit-card.svg";
import { ReactComponent as PayPal } from "./../../../assets/svg/payPal.svg";
import BraintreeCard from "../BraintreeCard";
import BraintreePaypal from "../BraintreePaypal";
import Divider from "./../Divider";
import Icon, { IconTypes } from "../Icon";
import BraintreeGooglePay from "../BraintreeGooglePay";
import BraintreeApplePay from "../BraintreeApplePay";
import { isAndroid, isIOS, isSafari } from "react-device-detect";
import Spinner from "../Spinner";
import * as Sentry from "@sentry/react";

import classNames from "classnames";
import styles from "./styles.module.scss";
import PaymentFailed from "../PaymentFailed";
import ReactPixel from "react-facebook-pixel";
import { sentryLog } from "../../helpers/sentryLog";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";
import useBackendLog from "../../hooks/useBackendLog";
import { useTranslation } from "react-i18next";

export enum PaymentMethods {
  PAYPAL = "PAYPAL",
  BRAINTREE = "BRAINTREE",
}

interface IProps {
  handleClose: () => void;
  total: string;
  plans: IPlan[];
  selectedPlan: number;
  isPaymentFailed: boolean;
  setIsPaymentFailed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const Payment: FC<IProps> = ({
  handleClose,
  total,
  selectedPlan,
  plans,
  isPaymentFailed,
  setIsPaymentFailed,
  setIsPaymentProgress,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethods>(PaymentMethods.PAYPAL);

  const [braintreeClientInstance, setBraintreeClientInstance] =
    useState<Client | null>(null);

  const { token, loading } = useToken();
  const navigate = useNavigate();

  const { onBackendLog } = useBackendLog();

  const handlePaymentMethodClick = (method: PaymentMethods) => {
    setSelectedPaymentMethod(method);
  };

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  useEffect(() => {
    if (token && !loading) {
      braintree.client.create(
        {
          authorization: token,
        },
        (clientErr, clientInstance: Client) => {
          if (clientErr) {
            console.error(clientErr);
            Sentry.captureException(clientErr);
            return;
          }

          setBraintreeClientInstance(clientInstance);
        }
      );
    }
  }, [token, loading]);

  const onPaymentMethodReceived = useCallback(
    async (nonce: string) => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const add = queryParams.get("add");

        const plan = plans[selectedPlan];

        const body = {
          email: localStorage.getItem("payment_email"),
          fbc: null,
          fbp: null,
          nonce: nonce,
          plan_id: plan.braintree_id,
          // plan_id: "1M_9_99_then_29_99_MHealth_Jun23",
          user_anonymous_id: "",
          forher: true,
        };

        const url = window.location.href;

        const response = await api.post(
          ApiPaths.BRAINTREE_SUBSCRIPTION_NOAUTH,
          JSON.stringify(body),
          {
            headers: {
              Referer: url,
            },
          }
        );

        if (response.status === 200) {
          const { data } = response;
          const payment_email = localStorage.getItem("payment_email");
          const eventID = data.event_id ? data.event_id : uuidv4();
          const pixelValue = +plan.total.replace(/[^0-9.]/g, "");
          const LTV = data.ltv
            ? +data.ltv
            : +plan.total.replace(/[^0-9.]/g, "");

          ReactPixel.fbq(
            "track",
            "Purchase",
            {
              currency: "USD",
              plan: plan.id.toString(),
              value: LTV,
              external_id: data.braintree_user_id,
            },
            { eventID }
          );

          window.ttq.track("CompletePayment", {
            contents: [
              {
                content_id: plan.id.toString(),
                content_name: plan.id.toString(),
                quantity: 1,
                price: LTV,
              },
            ],
            content_type: "product",
            value: LTV,
            currency: "USD",
          });

          sentryLog("New Purchase registered", {
            currency: "USD",
            plan: plan.id.toString(),
            eventID,
            value: pixelValue,
            email: payment_email,
            em: payment_email,
            external_id: data.braintree_user_id,
          });

          if (response.data.deeplink) {
            localStorage.setItem("deeplink", response.data.deeplink);
          }
          if (response.data.braintree_user_id) {
            localStorage.setItem(
              "braintree_user_id",
              response.data.braintree_user_id
            );
          }

          ReactGA.gtag({
            event_category: plan.id.toString(),
            event_label: pixelValue,
            plan_name: plan.id.toString(),
            value: pixelValue,
          });

          if (localStorage.getItem("discount_applied")) {
            ReactGA.gtag("event", "special_offer_purchase", {});
          }

          if (add === "supp") {
            localStorage.setItem(
              "isPaymentSuccessWithPreSale",
              JSON.stringify(data)
            );
            navigate("/packs");
            setIsPaymentProgress(false);
          } else if (add === "pdf1" || add === "pdf2" || add === "pdf3") {
            localStorage.setItem(
              "isPaymentSuccessWithPreSale",
              JSON.stringify(data)
            );
            navigate(`/presale-guide?add=${add}`);
            setIsPaymentProgress(false);
          } else {
            navigate("/payment-success");
          }
        } else {
          setIsPaymentProgress(false);
          setIsPaymentFailed(true);
          sentryLog("Payment recived but not handled from our backend", {
            response: response,
            payload: body,
          });
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      } catch (e) {
        setIsPaymentProgress(false);
        setIsPaymentFailed(true);
        Sentry.captureException(e);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }
    },
    // eslint-disable-next-line
    [navigate, plans, selectedPlan, setIsPaymentProgress, location]
  );

  useEffect(() => {
    if (token && braintreeClientInstance) {
      onBackendLog({
        type: "payment_form_loaded",
      });
    }
  }, [token, braintreeClientInstance, onBackendLog]);

  const shoudlRenderPayments = token && braintreeClientInstance;

  return (
    <div className={styles.wrapper}>
      <PaymentFailed
        isPaymentFailed={isPaymentFailed}
        setIsPaymentFailed={setIsPaymentFailed}
      />
      <span onClick={handleClose} className={styles.close}>
        <Icon type={IconTypes.CROSS} size={24} />
      </span>
      <div
        className={classNames([
          styles.paymentFields,
          { [styles.isActive]: !isPaymentFailed },
        ])}
      >
        <p className={styles.title}>{t("paywall_gselect_payment_method")}</p>
        <div className={styles.methods}>
          <span
            className={classNames({
              [styles.active]: selectedPaymentMethod === PaymentMethods.PAYPAL,
            })}
            onClick={() => handlePaymentMethodClick(PaymentMethods.PAYPAL)}
          >
            <PayPal />
          </span>
          <span
            className={classNames({
              [styles.active]:
                selectedPaymentMethod === PaymentMethods.BRAINTREE,
            })}
            onClick={() => handlePaymentMethodClick(PaymentMethods.BRAINTREE)}
          >
            <CredtitCard />
          </span>
        </div>
        {braintreeClientInstance && isAndroid && (
          <BraintreeGooglePay
            braintreeClientInstance={braintreeClientInstance}
            onPaymentMethodReceived={onPaymentMethodReceived}
            setIsPaymentProgress={setIsPaymentProgress}
            total={total}
          />
        )}

        {braintreeClientInstance && (isIOS || isSafari) && (
          <BraintreeApplePay
            braintreeClientInstance={braintreeClientInstance}
            onPaymentMethodReceived={onPaymentMethodReceived}
            setIsPaymentProgress={setIsPaymentProgress}
            total={total}
          />
        )}

        <Divider top={24} isMenu />
        {shoudlRenderPayments ? (
          <>
            <div className={styles.total}>
              <span>{t("paywall_total")}</span>
              <span>{total}</span>
            </div>
            <div
              className={classNames(styles.braintree, styles.paymentForm, {
                [styles.active]:
                  selectedPaymentMethod === PaymentMethods.BRAINTREE,
              })}
            >
              <BraintreeCard
                token={token}
                onPaymentMethodReceived={onPaymentMethodReceived}
                setIsPaymentProgress={setIsPaymentProgress}
                recaptchaRef={recaptchaRef}
              />
            </div>
            <div
              className={classNames(styles.paypal, styles.paymentForm, {
                [styles.active]:
                  selectedPaymentMethod === PaymentMethods.PAYPAL,
              })}
            >
              <BraintreePaypal
                braintreeClientInstance={braintreeClientInstance}
                onPaymentMethodReceived={onPaymentMethodReceived}
                setIsPaymentProgress={setIsPaymentProgress}
              />
            </div>
          </>
        ) : (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
