import { FC } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import congrads_img from "./../../../assets/cancel/congrads.png";
import { useTranslation } from "react-i18next";

interface ICancelStepOfferAccepted {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
}

const CancelStepOfferAccepted: FC<ICancelStepOfferAccepted> = ({
  onBackToApp,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.close} onClick={onBackToApp}>
          <Icon type={IconTypes.CROSS} size={12} />
        </div>
      </div>
      <div className={styles.logo}></div>
      <div className={styles.congrads}>{t("cancel_flow_congrads")}</div>
      <img alt="congrads" src={congrads_img} className={styles.img} />
      <div className={styles.title}>
        {t("cancel_flow_special_offer_activated")}
      </div>
      <button className={styles.buttonStay} onClick={onBackToApp}>
        {t("cancel_flow_great_button")}
      </button>
    </div>
  );
};

export default CancelStepOfferAccepted;
