import { FC } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";
import QuestionnaireProgressBar from "../QuestionnaireProgressBar";
import { useTranslation } from "react-i18next";

interface IQuestionaireHeader {
  current: number;
  total: number;
  badge: string;
  onPrevQuestion: () => void;
}

const QuestionnaireHeader: FC<IQuestionaireHeader> = ({
  current,
  total,
  badge,
  onPrevQuestion,
}) => {
  const { t } = useTranslation();

  const hasPrevBtn = current !== 0 && current + 1 !== total;
  return (
    <div className={styles.wrapper}>
      {hasPrevBtn && (
        <div className={styles.left} onClick={onPrevQuestion}>
          <Icon type={IconTypes.ARROW_LEFT} size={15} />
        </div>
      )}

      <div className={styles.right}>
        <div className={styles.steps}>
          {t("main_male_questionnaire_step_label_1")} {current + 1}/{total}
        </div>
        <div className={styles.question}>
          <Icon type={IconTypes.TARGET} size={15} />
          {badge}
        </div>
        <div className={styles.progress}>
          <QuestionnaireProgressBar filled={((current + 1) / total) * 100} />
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireHeader;
