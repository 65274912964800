import styles from "./styles.module.scss";
import image from "./../../../../assets/formula/img.png";
import { ReactNode } from "react";

const ListItem = ({ children }: { children: ReactNode }) => {
  return (
    <p className={styles.listItem}>
      <span className={styles.icon} />
      {children}
    </p>
  );
};

const Formula = () => {
  return (
    <div className={styles.block}>
      <img src={image} alt="banner" className={styles.image} />
      <h3 className={styles.title}>The Coach Formula 01</h3>
      <p className={styles.describtion}>
        Based on your survey results, we have selected the right supplements for
        you
      </p>
      <ListItem>Contains important arginine and citrulline</ListItem>
      <ListItem>Boost libido</ListItem>
      <ListItem>Strengthens erections</ListItem>
      <ListItem>Prevents premature ejaculation</ListItem>
    </div>
  );
};

export default Formula;
