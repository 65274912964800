import { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as Clock } from "./../../../assets/svg/clock-circular.svg";
import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import cls from "classnames";
import { useTranslation } from "react-i18next";

const DiscountTimer: FC = () => {
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [time, setTime] = useState(600);
  const { t } = useTranslation();

  const theme = getQuestionnaireTheme();
  const isWhite = QUESTIONNAIRE_THEME.WHITE === theme;

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () =>
      timerRef.current ? clearInterval(timerRef.current) : undefined;
  }, []);

  useEffect(() => {
    if (timerRef.current && time <= 0) {
      clearInterval(timerRef.current);
    }
  }, [time, timerRef]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className={cls(styles.discountBlock, { [styles.isWhite]: isWhite })}>
      <Clock />{" "}
      <p>
        {" "}
        {formatTime(time)} {t("paywall_discount_expires")}
      </p>
    </div>
  );
};

export default DiscountTimer;
