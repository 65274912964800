import { FC } from "react";
import SubTitle from "../SubTitle";
import Reviews from "../Reviews";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface IReview {
  star: number;
  text: string;
  userName: string;
}

interface IReviewAndRatings {
  reviews: IReview[];
}

const ReviewsAndRatings: FC<IReviewAndRatings> = ({ reviews }) => {
  const { t } = useTranslation();

  return (
    <>
      <SubTitle text={t("paywall_user_reviews_header")} />

      <div className={styles.reviews}>
        {reviews.map((review, index) => (
          <Reviews
            key={index}
            stars={review.star}
            text={t(review.text)}
            userName={t(review.userName)}
          />
        ))}
      </div>
    </>
  );
};

export default ReviewsAndRatings;
