import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";

interface ICancelStepReason {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
  user_id?: string;
}

const CancelStepReason: FC<ICancelStepReason> = ({
  onNextStep,
  onBackToApp,
  user_id,
}) => {
  const { t } = useTranslation();
  const [activeQuestion, setActiveQUestion] = useState<number>();

  const onQuestionSelect = (question: number) => {
    setActiveQUestion(question);
  };

  const onContinue = () => {
    switch (activeQuestion) {
      case 0:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Supplements",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 1:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Medication",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 2:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Another app",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 3:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Visit a doctor",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 4:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "I will handle it on my own",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 5:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Won’t address the issue at all",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 6:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "I’ve already solved the problem",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 7:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Other",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      default:
        amplitude.track({
          event_type: "Cancel_alternatives_open",
          event_properties: {
            answer: "Not Selected",
          },
          user_id: user_id,
        });
    }

    onNextStep(CANCEL_STEPS.THEOFFER);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.header}>
          <div className={styles.close} onClick={onBackToApp}>
            <Icon type={IconTypes.CROSS} size={12} />
          </div>
        </div>
        <div className={styles.title}>
          {t("cancel_flow_alternative_question")}
        </div>
        <div className={styles.questions}>
          <div
            className={`${styles.question} ${activeQuestion === 0 && styles.active}`}
            onClick={() => onQuestionSelect(0)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_supplements")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 1 && styles.active}`}
            onClick={() => onQuestionSelect(1)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_medication")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 2 && styles.active}`}
            onClick={() => onQuestionSelect(2)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_another_app")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 3 && styles.active}`}
            onClick={() => onQuestionSelect(3)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_doctor")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 4 && styles.active}`}
            onClick={() => onQuestionSelect(4)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_handle_myself")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 5 && styles.active}`}
            onClick={() => onQuestionSelect(5)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_ignore")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 6 && styles.active}`}
            onClick={() => onQuestionSelect(6)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_solved")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 7 && styles.active}`}
            onClick={() => onQuestionSelect(7)}
          >
            <div className={styles.label}>
              {t("cancel_flow_alternative_other")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bootom}>
        <button className={styles.buttonStay} onClick={onBackToApp}>
          {t("cancel_flow_dont_leave_button")}
        </button>
        <button className={styles.buttonProceed} onClick={onContinue}>
          {t("cancel_flow_continue")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepReason;
