import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS, IUserData } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import { validateEmail } from "../../helpers/cardValidation";
import { api, ApiPaths } from "../../api";
import { useTranslation } from "react-i18next";

interface ICancelStepReminder {
  onNextStep: (step: CANCEL_STEPS) => void;
  user_id?: string;
  userData: IUserData | undefined;
  onBackToApp: () => void;
}

const CancelStepReminder: FC<ICancelStepReminder> = ({
  onNextStep,
  user_id,
  userData,
  onBackToApp,
}) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState("");
  const [emailInputError, setEmailInputError] = useState(false);
  const [onRemindLoading, setOnRemindLoading] = useState(false);

  const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);

    if (validateEmail(emailInput)) {
      setEmailInputError(false);
    }
  };

  const onEmailInputBlur = () => {
    setEmailInputError(!validateEmail(emailInput));
  };

  const onRemindSend = async () => {
    setOnRemindLoading(true);
    try {
      let date_increesed;
      if (userData) {
        const newDate = new Date(userData.paid_until);
        newDate.setDate(newDate.getDate() - 3);
        date_increesed = newDate.toISOString().split("T")[0];
      }

      await api.post(ApiPaths.CANCEL_REMINDER, {
        uid: user_id,
        email: emailInput,
        date: date_increesed,
      });
    } catch (e: any) {
      console.log(e);
    }

    onBackToApp();
    setOnRemindLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.header}>
          <div className={styles.close} onClick={onBackToApp}>
            <Icon type={IconTypes.CROSS} size={12} />
          </div>
        </div>
        <div className={styles.logo}></div>
        <div className={styles.title}>{t("cancel_flow_dont_worry")}</div>
        <div className={styles.description}>
          {t("cancel_flow_subscription_reminder")}
        </div>
        <div className={styles.field}>
          <input
            type="email"
            placeholder={t("cancel_flow_enter_your_email") || ""}
            value={emailInput}
            onChange={onEmailInputChange}
            onBlur={onEmailInputBlur}
          />
        </div>
        {emailInputError && (
          <div className={styles.error}>{t("cancel_flow_valid_email")}</div>
        )}
        <div className={styles.description}>
          {t("cancel_flow_active_subscription_perks")}
        </div>
        <div className={styles.points}>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>
              {t("cancel_flow_lose_daily_plan")}
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>{t("cancel_flow_lose_progress")}</div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>{t("cancel_flow_lose_lessons")}</div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>
              {t("cancel_flow_lose_motivation")}
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>
              {t("cancel_flow_perk_supplements_discount")}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bootom}>
        <button
          className={styles.buttonStay}
          disabled={emailInputError || !validateEmail(emailInput)}
          onClick={() => {
            onRemindSend();
          }}
        >
          {onRemindLoading
            ? t("cancel_flow_loading")
            : t("cancel_flow_remind_me_button")}
        </button>
        <button
          className={styles.buttonProceed}
          onClick={() => onNextStep(CANCEL_STEPS.REASON)}
        >
          {t("cancel_flow_cancel_subscription")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepReminder;
