import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS, IUserData } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import cancel_ofer_image from "./../../../assets/cancel/cancel_offer.png";
import cancel_ofer_image_es from "./../../../assets/cancel/cancel_offer_es.png";
import { api, ApiPaths } from "../../api";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";

interface ICancelStepTheOffer {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
  user_id: string;
  userData?: IUserData;
}

const CancelStepTheOffer: FC<ICancelStepTheOffer> = ({
  onNextStep,
  onBackToApp,
  user_id,
  userData,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");
  const isES = lng === "es";

  const { t } = useTranslation();
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState<boolean>(false);

  const [offerSubscriptionLoading, setOfferSubscriptionLoading] =
    useState<boolean>(false);

  const onCancelSunscription = async (onSuccess: () => void) => {
    amplitude.track({
      event_type: "Account_cancel_success",
      user_id: user_id,
    });

    setCancelSubscriptionLoading(true);
    try {
      const response = await api.delete(
        ApiPaths.BRAINTREE_STATUS + "?user_id=" + user_id,
        {
          data: {
            subscription_id: userData?.subscription.id,
          },
        }
      );

      if (response.status === 200) {
        onSuccess();
      }
    } catch (e: any) {
      console.log(e);
    }
    setCancelSubscriptionLoading(false);
  };

  const accseptTheOffer = async () => {
    amplitude.track({
      event_type: "Account_cancel_success",
      user_id: user_id,
    });

    setOfferSubscriptionLoading(true);

    try {
      const response = await api.delete(
        ApiPaths.BRAINTREE_STATUS + "?user_id=" + user_id,
        {
          data: {
            subscription_id: userData?.subscription.id,
          },
        }
      );

      if (response.status === 200) {
        amplitude.track({
          event_type: "Cancel_accept_first_SO_clicked",
          user_id: user_id,
        });

        const formData = new FormData();
        formData.append("userid", user_id ?? "");
        formData.append("email", userData?.email ?? "");
        formData.append(
          "braintree_user_id",
          userData?.subscription.amplitude.braintree_user_id ?? ""
        );
        formData.append("category", "Special Offer 3M19.99 Accepted");
        formData.append(
          "comment",
          `User accepted offer Special Offer 3M19.99 Accepted

    email: ${userData?.email}

    Amplitude ID: ${user_id}

    braintree_id: ${userData?.subscription.amplitude.braintree_user_id}`
        );

        const offer_response = await api.post(
          ApiPaths.ACCEPT_CANCEL_OFFER,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (offer_response.status === 200) {
          onNextStep(CANCEL_STEPS.OFFER_ACCEPTED);
        }
      }
    } catch (e: any) {
      console.log(e);
    }

    setOfferSubscriptionLoading(false);
  };

  if (!userData) return <>User Data is missing, Please reload the page</>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.header}>
          <div className={styles.close} onClick={onBackToApp}>
            <Icon type={IconTypes.CROSS} size={12} />
          </div>
        </div>
        <div className={styles.logo}></div>
        <img
          alt="cancel_offer"
          src={isES ? cancel_ofer_image_es : cancel_ofer_image}
          className={styles.cancelOffer}
        />
        <div className={styles.block}>
          <div className={styles.priceBlock}>
            <div className={styles.price}>$ 19.99</div>
            <div className={styles.time}>{t("cancel_flow_price_3_months")}</div>
          </div>

          <div className={styles.title}>
            {t("cancel_flow_money_not_barrier")}
          </div>
          <div className={styles.description}>
            {t("cancel_flow_exclusive_access")}
          </div>
        </div>
      </div>

      <div className={styles.bootom}>
        <button className={styles.buttonStay} onClick={() => accseptTheOffer()}>
          {offerSubscriptionLoading
            ? t("cancel_flow_loading")
            : t("cancel_flow_accept_offer_button")}
        </button>
        <button
          className={styles.buttonProceed}
          onClick={() =>
            onCancelSunscription(() => onNextStep(CANCEL_STEPS.CANCELED))
          }
        >
          {cancelSubscriptionLoading
            ? t("cancel_flow_loading")
            : t("cancel_flow_cancel_subscription")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepTheOffer;
