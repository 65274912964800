import React, {
  useEffect,
  FC,
  useRef,
  useState,
  FormEventHandler,
} from "react";
import dropin from "braintree-web-drop-in";
import {
  createEmailInput,
  setEmailError,
} from "../../helpers/createEmailInput";
import styles from "./styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from "@sentry/react";
import useBackendLog from "../../hooks/useBackendLog";

interface IProps {
  token: string;
  onPaymentMethodReceived: (nonce: string) => void;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
  recaptchaRef: React.MutableRefObject<ReCAPTCHA | null>;
}

const BRAINTREE_CONTAINER_ID = "drop-in-container";

const BraintreeCard: FC<IProps> = ({
  token,
  onPaymentMethodReceived,
  setIsPaymentProgress,
  recaptchaRef,
}) => {
  const [dropInInstance, setDropInInstance] = useState<dropin.Dropin | null>(
    null
  );
  const [showRecaptchaError] = useState<boolean>(false);
  const [cardInstance, setCardInstance] = useState<dropin.Dropin | undefined>();
  const [isBraintreeMonthed, setIsBraintreeMonthed] = useState(false);

  const isEmailValid = useRef<null | string>("This field is required");
  const formRef = useRef<HTMLFormElement>(null);
  const { onBackendLog } = useBackendLog();
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");

  const handleVerifiedSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    onBackendLog({
      type: "pay_clicked",
    });

    if (recaptchaRef.current) {
      onBackendLog({
        type: "google_captcha_executed",
      });

      recaptchaRef.current.execute();
    }
  };

  const handleSubmit = () => {
    if (cardInstance) {
      if (!isEmailValid.current) {
        onBackendLog({
          type: "payment_request_to_back_send",
        });

        setIsPaymentProgress(true);
        cardInstance?.requestPaymentMethod((err, payload) => {
          if (err) {
            setIsPaymentProgress(false);
            Sentry.captureException(err);

            onBackendLog({
              type: "payment_request_failed",
              reason: err,
            });

            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
            }
            return;
          }
          onPaymentMethodReceived(payload.nonce);
        });
      } else {
        setEmailError(false, isEmailValid);

        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }
    }
  };

  useEffect(() => {
    const createDropinContainer = () => {
      if (!isBraintreeMonthed) {
        dropin.create(
          {
            authorization: token,
            container: `#${BRAINTREE_CONTAINER_ID}`,
            locale: lng === "es" ? "es_ES" : "en_US",
            card: {
              cardholderName: false,
              overrides: {
                fields: {
                  cvv: { placeholder: "123" },
                },
              },
            },
          },
          (err, instance) => {
            if (err) {
              console.error(err);
              Sentry.captureException(err);
              return;
            }
            setIsBraintreeMonthed(true);
            createEmailInput(isEmailValid);
            setCardInstance(instance);
          }
        );
      }
    };

    createDropinContainer();

    return () => {
      !isBraintreeMonthed &&
        dropInInstance?.teardown().then(() => {
          setDropInInstance(null);
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    onPaymentMethodReceived,
    setIsPaymentProgress,
    isBraintreeMonthed,
  ]);

  const handleRecaptchaChange = (value: string | null) => {
    if (value) {
      onBackendLog({
        type: "google_captcha_accepted",
      });
      handleSubmit();
    } else {
      onBackendLog({
        type: "google_captcha_rejected",
      });
    }
  };

  return (
    <form ref={formRef} className={styles.form} onSubmit={handleVerifiedSubmit}>
      <div id={BRAINTREE_CONTAINER_ID} className={styles.dropIn}></div>
      <div className={styles.recaptcha}>
        <div style={{ display: "none" }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
            size="invisible"
            onChange={handleRecaptchaChange}
            badge={"inline"}
          />
        </div>
        {showRecaptchaError && (
          <p className={styles.recaptchaMessage}>
            Please complete the reCAPTCHA.
          </p>
        )}
      </div>

      <button className={styles.pay}>Pay</button>
    </form>
  );
};

export default BraintreeCard;
