import styles from "./styles.module.scss";

interface IProps {
  img: string;
  title: string;
  describtion: string;
  direction: "left" | "right";
}

const WhatsImportant = ({ img, title, describtion, direction }: IProps) => {
  return (
    <div className={styles.block + " " + styles[direction]}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.describtion}>{describtion}</p>
      </div>
      <img
        src={img}
        alt="banner"
        className={styles.image}
        width="110px"
        height="140px"
      />
    </div>
  );
};

export default WhatsImportant;
