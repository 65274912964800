import { InputHTMLAttributes } from "react";
import styles from "./style.module.scss";
interface IProps {
  deal: string;
  freeDelivery: boolean;
  mostPopular: boolean;
  value: string;
  price: string;
  title: string;
  totalPrevPrice?: string;
  totalPrice: string;
  item: string;
}
const Radio = ({
  deal,
  freeDelivery,
  mostPopular,
  value,
  price,
  title,
  totalPrice,
  totalPrevPrice,
  item,
  ...inputProps
}: InputHTMLAttributes<HTMLInputElement> & IProps) => {
  return (
    <label>
      <div className={styles.container}>
        <header>
          <p className={styles.deal}>{deal}</p>
          <div className={styles.badges}>
            {freeDelivery && (
              <p className={styles.free + " " + styles.badge}>free delivery</p>
            )}
            {mostPopular && (
              <p className={styles.popular + " " + styles.badge}>
                most popular
              </p>
            )}
          </div>
        </header>
        <main>
          <div className={styles.content}>
            <p className={styles.heading}>
              <span className={styles.title}>{title}</span>
              <span className={styles.price}>{price}</span>
            </p>
            <p className={styles.description}>
              <span className={styles.total}>
                Total:
                {!!totalPrevPrice && (
                  <span className={styles.prevPrice}>{totalPrevPrice}</span>
                )}
                {totalPrice}
              </span>
              <span className={styles.item}>/{item}</span>
            </p>
          </div>
          <span className={styles.icon}>
            <input
              {...inputProps}
              type="radio"
              id="radio"
              value={totalPrice}
              checked={value === totalPrice}
            />
          </span>
        </main>
      </div>
    </label>
  );
};

export default Radio;
