import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import { api, ApiPaths } from "../../api";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

interface ICancelStepFeedback {
  onNextStep: (step: CANCEL_STEPS) => void;
  user_id: string;
  email: string;
  onBackToApp: () => void;
}

const CancelStepFeedback: FC<ICancelStepFeedback> = ({
  onNextStep,
  email,
  user_id,
  onBackToApp,
}) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState<string>("");
  const [feedbackLoading, setFeedbackLoading] = useState<boolean>(false);

  const onFeedbackSend = async (onSuccess: () => void) => {
    setFeedbackLoading(true);
    try {
      const response = await api.post(ApiPaths.CANCEl_FEEDBACK, {
        user_id: user_id,
        email: email,
        feedback: feedback,
        category: "Other",
      });

      if (response.status === 200) {
        onSuccess();
      }
    } catch (e: any | AxiosError) {
      console.log(e);
    }
    setFeedbackLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.header}>
          <div className={styles.close} onClick={onBackToApp}>
            <Icon type={IconTypes.CROSS} size={12} />
          </div>
        </div>
        <div className={styles.logo}></div>
        <div className={styles.title}>
          {t("cancel_flow_leaving_reason_title")}
        </div>
        <div className={styles.field}>
          <textarea
            placeholder={t("cancel_flow_text_placeholder") || ""}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
        </div>
      </div>

      <div className={styles.bootom}>
        <button
          className={styles.buttonStay}
          onClick={() =>
            onFeedbackSend(() => {
              onNextStep(CANCEL_STEPS.REASON);
            })
          }
        >
          {feedbackLoading
            ? t("cancel_flow_loading")
            : t("cancel_flow_submit_button")}
        </button>
        <button
          className={styles.buttonProceed}
          onClick={() => onNextStep(CANCEL_STEPS.REASON)}
        >
          {t("cancel_flow_without_weedback")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepFeedback;
