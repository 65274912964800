import { FC, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import braintree, { Client } from "braintree-web";
// import { ApiPaths, api } from "../../api";
import { IPlan } from "../../hooks/usePlans";
// import useToken from "../../hooks/useToken";
import { ReactComponent as CredtitCard } from "./../../../assets/svg/credit-card.svg";
import { ReactComponent as PayPal } from "./../../../assets/svg/payPal.svg";
// import BraintreeCard from "../BraintreeCard";
// import BraintreePaypal from "../BraintreePaypal";
import Divider from "./../Divider";
import Icon, { IconTypes } from "../Icon";
// import BraintreeGooglePay from "../BraintreeGooglePay";
// import BraintreeApplePay from "../BraintreeApplePay";
// import { isAndroid, isIOS, isSafari } from "react-device-detect";
import Spinner from "../Spinner";
// import * as Sentry from "@sentry/react";

import classNames from "classnames";
import styles from "./styles.module.scss";
import PaymentFailed from "../PaymentFailed";
// import ReactPixel from "react-facebook-pixel";
// import { sentryLog } from "../../helpers/sentryLog";
// import ReCAPTCHA from "react-google-recaptcha";
// import ReactGA from "react-ga4";
// import useBackendLog from "../../hooks/useBackendLog";
import { useTranslation } from "react-i18next";
import RecurlyCard from "../RecurlyCard";

export enum PaymentMethods {
  PAYPAL = "PAYPAL",
  BRAINTREE = "BRAINTREE",
}

interface IProps {
  handleClose: () => void;
  total: string;
  plans: IPlan[];
  selectedPlan: number;
  isPaymentFailed: boolean;
  setIsPaymentFailed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecurlyPayment: FC<IProps> = ({
  handleClose,
  total,
  // selectedPlan,
  // plans,
  isPaymentFailed,
  setIsPaymentFailed,
  setIsPaymentProgress,
}) => {
  const { t } = useTranslation();

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethods>(PaymentMethods.PAYPAL);

  const handlePaymentMethodClick = (method: PaymentMethods) => {
    setSelectedPaymentMethod(method);
  };

  const shoudlRenderPayments = true;

  return (
    <div className={styles.wrapper}>
      <PaymentFailed
        isPaymentFailed={isPaymentFailed}
        setIsPaymentFailed={setIsPaymentFailed}
      />
      <span onClick={handleClose} className={styles.close}>
        <Icon type={IconTypes.CROSS} size={24} />
      </span>
      <div
        className={classNames([
          styles.paymentFields,
          { [styles.isActive]: !isPaymentFailed },
        ])}
      >
        <p className={styles.title}>{t("paywall_gselect_payment_method")}</p>
        <div className={styles.methods}>
          <span
            className={classNames({
              [styles.active]: selectedPaymentMethod === PaymentMethods.PAYPAL,
            })}
            onClick={() => handlePaymentMethodClick(PaymentMethods.PAYPAL)}
          >
            <PayPal />
          </span>
          <span
            className={classNames({
              [styles.active]:
                selectedPaymentMethod === PaymentMethods.BRAINTREE,
            })}
            onClick={() => handlePaymentMethodClick(PaymentMethods.BRAINTREE)}
          >
            <CredtitCard />
          </span>
        </div>
        {/* {braintreeClientInstance && isAndroid && (
          <BraintreeGooglePay
            braintreeClientInstance={braintreeClientInstance}
            onPaymentMethodReceived={onPaymentMethodReceived}
            setIsPaymentProgress={setIsPaymentProgress}
            total={total}
          />
        )}

        {braintreeClientInstance && (isIOS || isSafari) && (
          <BraintreeApplePay
            braintreeClientInstance={braintreeClientInstance}
            onPaymentMethodReceived={onPaymentMethodReceived}
            setIsPaymentProgress={setIsPaymentProgress}
            total={total}
          />
        )} */}

        <Divider top={24} isMenu />
        {shoudlRenderPayments ? (
          <>
            <div className={styles.total}>
              <span>{t("paywall_total")}</span>
              <span>{total}</span>
            </div>
            <div
              className={classNames(styles.braintree, styles.paymentForm, {
                [styles.active]:
                  selectedPaymentMethod === PaymentMethods.BRAINTREE,
              })}
            >
              {/* <BraintreeCard
                token={token}
                onPaymentMethodReceived={onPaymentMethodReceived}
                setIsPaymentProgress={setIsPaymentProgress}
                recaptchaRef={recaptchaRef}
              /> */}
              <RecurlyCard
                token={"token"}
                onPaymentMethodReceived={() => {}}
                setIsPaymentProgress={setIsPaymentProgress}
              />
            </div>
            <div
              className={classNames(styles.paypal, styles.paymentForm, {
                [styles.active]:
                  selectedPaymentMethod === PaymentMethods.PAYPAL,
              })}
            >
              Paypall
              {/* <BraintreePaypal
                braintreeClientInstance={braintreeClientInstance}
                onPaymentMethodReceived={onPaymentMethodReceived}
                setIsPaymentProgress={setIsPaymentProgress}
              /> */}
            </div>
          </>
        ) : (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecurlyPayment;
