import { FC } from "react";
import { ReactComponent as Circle } from "./../../../assets/svg/circl.svg";
import SubTitle from "../SubTitle";
import cls from "classnames";
import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import { useTranslation } from "react-i18next";

interface IResults {
  title: string;
}

const Results: FC<IResults> = ({ title }) => {
  const { t } = useTranslation();
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  return (
    <section className={styles.results}>
      <SubTitle text={title} />

      <div className={cls(styles.content, { [styles.isWhite]: isWhite })}>
        <Circle />

        <div className={styles.percentBlock}>
          <span className={styles.percent}>
            90 <span>%</span>
          </span>
          <p>{t("paywall_improvement_stats")}</p>
        </div>
      </div>
    </section>
  );
};

export default Results;
