import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { validateEmail } from "../../helpers/cardValidation";
import { CANCEL_STEPS } from "../../pages/Cancel";
import { api, ApiPaths } from "../../api";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";

interface ICancelStepAuth {
  onNextStep: (step: CANCEL_STEPS) => void;
  onSetEmail: (email: string) => void;
  onSetToken: (token: string) => void;
  contactUs: (theme: string) => void;
  user_id: string;
}

const enum AUTH_STEPS {
  LOGIN = "LOGIN",
  VERIFICATION = "VERIFICATION",
}

const CancelStepAuth: FC<ICancelStepAuth> = ({
  onNextStep,
  onSetEmail,
  onSetToken,
  contactUs,
  user_id,
}) => {
  const { t } = useTranslation();
  const [authStep, setAuthStep] = useState<AUTH_STEPS>(AUTH_STEPS.LOGIN);

  const [emailInput, setEmailInput] = useState("");
  const [emailInputError, setEmailInputError] = useState(false);
  const [emailInputNotFound, setEmailInputNotFound] = useState(false);

  const [codeInput, setCodeInput] = useState("");
  const [codeInputError, setCodeInputError] = useState(false);
  const [codeInputWrong, setCodeInputErrorWrong] = useState(false);

  const [resendTimer, setResendTimer] = useState<number>();

  const [continueAuthBTNLoading, setContinueAuthBTNLoading] = useState(false);
  const [resendCodeBTNLoading, setResendCodeBTNLoading] = useState(false);

  const onEmailVerify = async (onSuccess: () => void) => {
    try {
      const response = await api.post(ApiPaths.VERIFY_EMAIL, {
        email: emailInput,
      });

      if (response.status === 200) {
        onSuccess();
      }
    } catch (e: any | AxiosError) {
      if (e?.response?.status === 403) {
        setEmailInputNotFound(true);
      }
    }
  };

  const onEmailCodeVerify = async (onSuccess: () => void) => {
    try {
      const response = await api.get(
        ApiPaths.VERIFY_EMAIL_CODE +
          "?email=" +
          emailInput +
          "&code=" +
          codeInput
      );

      if (response.status === 200) {
        onSetEmail(emailInput);
        onSetToken(response.data.token);
        onSuccess();
      }
    } catch (e: any | AxiosError) {
      if (e?.response?.status === 403) {
        setCodeInputErrorWrong(true);
      }
    }
  };

  const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    setEmailInputNotFound(false);

    if (validateEmail(emailInput)) {
      setEmailInputError(false);
    }
  };

  const onEmailInputBlur = () => {
    setEmailInputError(!validateEmail(emailInput));
  };

  const onCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeInput(e.target.value);
    setCodeInputErrorWrong(false);

    if (e.target.value.length === 4) {
      setCodeInputError(false);
    }
  };

  const onCodeInputBlur = () => {
    setCodeInputError(codeInput.length !== 4);
  };

  const onResendCodeClick = async () => {
    setResendCodeBTNLoading(true);
    await onEmailVerify(() => {
      setResendTimer(30);
    });
    setResendCodeBTNLoading(false);
  };

  const onContinueClick = async () => {
    switch (authStep) {
      case AUTH_STEPS.LOGIN:
        setContinueAuthBTNLoading(true);
        await onEmailVerify(() => {
          setAuthStep(AUTH_STEPS.VERIFICATION);
          setResendTimer(30);
        });
        setContinueAuthBTNLoading(false);
        break;
      case AUTH_STEPS.VERIFICATION:
        onEmailCodeVerify(() => {
          onNextStep(CANCEL_STEPS.ACCOUNT);
        });
    }
  };

  useEffect(() => {
    onSetEmail(emailInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailInput]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (resendTimer) {
      interval = setTimeout(() => {
        setResendTimer((prev) => prev && prev - 1);
      }, 1000);
    }

    if (resendTimer === 0) {
      clearInterval(interval);
      setResendTimer(undefined);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [resendTimer, setResendTimer]);

  useEffect(() => {
    if (user_id) {
      switch (authStep) {
        case AUTH_STEPS.LOGIN:
          amplitude.track({
            event_type: "Cancel_emailscreen_open",
            user_id: user_id,
          });
          break;
        case AUTH_STEPS.VERIFICATION:
          amplitude.track({
            event_type: "Cancel_otpscreen_open",
            user_id: user_id,
          });
          break;
      }
    }
  }, [authStep, user_id]);

  const isLoginContinueBtnDisabled = !validateEmail(emailInput);
  const isVerificationContinueBtnDisabled = codeInput.length !== 4;
  const isResetCodeeBtnDisabled = resendTimer !== undefined;

  const getAuthContent = () => {
    switch (authStep) {
      case AUTH_STEPS.LOGIN:
        return (
          <div className={styles.login}>
            <div className={styles.content}>
              <div className={styles.title}>{t("cancel_flow_enter_email")}</div>
              <div className={styles.description}>
                {t("cancel_flow_email_info")}
              </div>
              <div className={styles.email}>
                <input
                  type="email"
                  value={emailInput}
                  onChange={onEmailInputChange}
                  onBlur={onEmailInputBlur}
                  data-is-invalid={emailInputError}
                />
              </div>
              {emailInputError && (
                <div className={styles.error}>
                  {t("cancel_flow_valid_email")}
                </div>
              )}
              {emailInputNotFound && (
                <div className={styles.error}>
                  {t("cancel_flow_no_email_found_1")}
                  <br />
                  <br />
                  {t("cancel_flow_no_email_found_2")}{" "}
                  <span
                    className={styles.link}
                    onClick={() => contactUs("OTP+issue")}
                  >
                    {t("cancel_flow_support_team")}
                  </span>
                </div>
              )}
              <button
                className={styles.button}
                disabled={isLoginContinueBtnDisabled}
                onClick={
                  isLoginContinueBtnDisabled ? () => {} : onContinueClick
                }
              >
                {continueAuthBTNLoading
                  ? t("cancel_flow_loading")
                  : t("cancel_flow_continue")}
              </button>
            </div>
            <div className={styles.footer}>
              <div className={styles.block}>
                <div className={styles.note}>
                  {t("cancel_flow_purchase_store")}
                </div>
                <div
                  className={styles.link}
                  onClick={() => onNextStep(CANCEL_STEPS.INSTRUCTION)}
                >
                  {t("cancel_flow_see_instructions")} &gt;
                </div>
              </div>
            </div>
          </div>
        );
      case AUTH_STEPS.VERIFICATION:
        return (
          <div className={styles.verification}>
            <div className={styles.content}>
              <div className={styles.title}>
                {t("cancel_flow_login_code_sent")} {emailInput}.{" "}
                <span
                  className={styles.link}
                  onClick={() => setAuthStep(AUTH_STEPS.LOGIN)}
                >
                  {" "}
                  {t("cancel_flow_not_you")}
                </span>
              </div>
              <div className={styles.description}>
                {t("cancel_flow_enter_code")}
              </div>
              <div className={styles.email}>
                <input
                  type="number"
                  value={codeInput}
                  onChange={onCodeInputChange}
                  onBlur={onCodeInputBlur}
                  data-is-invalid={codeInputError}
                />
              </div>
              {codeInputWrong && (
                <div className={styles.error}>
                  {t("cancel_flow_verify_wrong")}
                </div>
              )}
              {codeInputError && (
                <div className={styles.error}>
                  {t("cancel_flow_verify_required")}
                </div>
              )}
              <button
                className={styles.button_secondary}
                disabled={isResetCodeeBtnDisabled}
                onClick={isResetCodeeBtnDisabled ? () => {} : onResendCodeClick}
              >
                {resendCodeBTNLoading
                  ? t("cancel_flow_loading")
                  : resendTimer
                    ? `${t("cancel_flow_resend_code")} ${resendTimer} ${t("cancel_flow_resend_code_sec")}`
                    : t("cancel_flow_resend")}
              </button>
              <button
                className={styles.button}
                disabled={isVerificationContinueBtnDisabled}
                onClick={
                  isVerificationContinueBtnDisabled ? () => {} : onContinueClick
                }
              >
                {t("cancel_flow_continue")}
              </button>
            </div>
            <div className={styles.footer}>
              <div className={styles.block}>
                <div className={styles.note}>
                  {t("cancel_flow_didnt_get_code")}
                </div>
                <div
                  className={styles.link}
                  onClick={() => contactUs("OTP+issue")}
                >
                  {t("cancel_flow_contact_us")}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return <div className={styles.wrapper}>{getAuthContent()}</div>;
};

export default CancelStepAuth;
