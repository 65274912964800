import { FC } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import saving1_img from "./../../../assets/cancel/saving_1.png";
import saving1_img_es from "./../../../assets/cancel/saving_1_es.png";
import saving2_img from "./../../../assets/cancel/saving_2.png";
import saving2_img_es from "./../../../assets/cancel/saving_2_es.png";
import Icon, { IconTypes } from "../Icon";
import { useTranslation } from "react-i18next";

interface ICancelStepSavings {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
}

const CancelStepSavings: FC<ICancelStepSavings> = ({
  onNextStep,
  onBackToApp,
}) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");
  const isES = lng === "es";

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.close} onClick={onBackToApp}>
          <Icon type={IconTypes.CROSS} size={12} />
        </div>
      </div>
      <div className={styles.logo}></div>
      <div className={styles.title}>
        {t("cancel_flow_doubts_smart_investment")}
      </div>
      <div className={styles.description}>
        {t("cancel_flow_doctor_visit_cost_1")}{" "}
        <span>{t("cancel_flow_doctor_visit_cost_2")}</span>{" "}
        {t("cancel_flow_doctor_visit_cost_3")}{" "}
        <span>{t("cancel_flow_doctor_visit_cost_4")}</span>{" "}
        {t("cancel_flow_doctor_visit_cost_5")}
      </div>

      <img
        alt="saving1_img"
        src={isES ? saving1_img_es : saving1_img}
        className={styles.img}
      />
      <div className={styles.description}>
        {t("cancel_flow_booster_cost_1")}{" "}
        <span>{t("cancel_flow_booster_cost_2")}</span>
        <br />
        {t("cancel_flow_long_term_solution_1")}{" "}
        <span>l{t("cancel_flow_long_term_solution_2")}</span>{" "}
        {t("cancel_flow_long_term_solution_3")}{" "}
        <span>{t("cancel_flow_long_term_solution_4")}</span>
      </div>
      <img
        alt="saving2_img"
        src={isES ? saving2_img_es : saving2_img}
        className={styles.img}
      />

      <div className={styles.actions}>
        <button className={styles.buttonStay} onClick={onBackToApp}>
          {t("cancel_flow_try_again")}
        </button>
        <button
          className={styles.buttonProceed}
          onClick={() => onNextStep(CANCEL_STEPS.REASON)}
        >
          {t("cancel_flow_cancel_subscription")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepSavings;
