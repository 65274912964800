import { FC } from "react";
import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  IQuestionnaireData,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import Icon, { IconTypes } from "../Icon";
import cls from "classnames";
import { useTranslation } from "react-i18next";

interface IQuestionnaireSingle {
  question: IQuestionnaireData;
  onNextQuestion: (answer: string) => void;
}

const QuestionnaireSingle: FC<IQuestionnaireSingle> = ({
  question: { questions },
  onNextQuestion,
}) => {
  const { t } = useTranslation();

  if (!questions) return null;

  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  return (
    <div className={cls(styles.wrapper, { [styles.isWhite]: isWhite })}>
      {questions.map((question) => (
        <div
          className={styles.question}
          onClick={() => onNextQuestion(question.value)}
        >
          <div className={styles.text}>{t(question.text)}</div>
          <div className={styles.icon}>
            <Icon type={IconTypes.ARROW_LEFT} size={10} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionnaireSingle;
