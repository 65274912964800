import { useEffect, useState } from "react";
import { api, ApiPaths } from "./../api";

export interface IToken {
  token: string;
}

const useToken = () => {
  const [token, setToken] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (token === null) {
        setLoading(true);
        const response = await api.get(ApiPaths.BRAINTREE_TOKEN);
        const {
          data: { token: tokenResponse },
        } = response;

        setToken(tokenResponse);
        setLoading(false);
      }
    })();
  }, [token]);

  return { token, loading };
};

export default useToken;
