import { FC } from "react";

import DiscountPerMonths from "../DiscountPerMonths";
import SubTitle from "../SubTitle";
import DiscountTimer from "../DicountTimer";
import { IPlan } from "../../hooks/usePlans";
import { ReactComponent as Visa } from "./../../../assets/svg/visa.svg";
import { ReactComponent as Master } from "./../../../assets/svg/master.svg";
import { ReactComponent as PayPal } from "./../../../assets/svg/payPal.svg";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  plans: IPlan[] | null;
  loading: boolean;
  selectedPlan: number;
  setSelectedPlan: React.Dispatch<React.SetStateAction<number>>;
}

const Plan: FC<IProps> = ({
  plans,
  loading,
  setSelectedPlan,
  selectedPlan,
}) => {
  const { t } = useTranslation();
  const handlePlanSelect = (index: number) => {
    setSelectedPlan(index);
  };

  return (
    <section>
      <DiscountTimer />

      <div className={styles.montlyPlans}>
        {loading ? (
          <>Loading...</>
        ) : (
          plans &&
          plans.map((plan, index) => (
            <DiscountPerMonths
              key={plan.id}
              planDuration={plan.duration}
              pricePerDay={plan.price_per_day}
              totalCrossed={plan.total_crossed}
              total={plan.total}
              offer={plan.offer}
              onClick={() => handlePlanSelect(index)}
              isSelected={selectedPlan === index}
            />
          ))
        )}

        {plans && plans[selectedPlan]?.has_trial_period ? (
          <p className={styles.warning}>{plans[selectedPlan].description}</p>
        ) : (
          <p className={styles.warning}>
            {t("paywall_subscription_notice_1")}{" "}
            {plans && plans[selectedPlan]?.description}{" "}
            {t("paywall_subscription_notice_2")}
          </p>
        )}
      </div>

      <div className={styles.paymentMethods}>
        <SubTitle text={t("paywall_safe_checkout")} />

        <div className={styles.paymentIcons}>
          <Visa />
          <Master />
          <PayPal />
          <img
            alt="ssl-secure"
            src="https://join.the.coach/img/content/ssl-secure.png"
            width="38px"
            height="16px"
          />
        </div>
      </div>
    </section>
  );
};

export default Plan;
