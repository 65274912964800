import { FC } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

export enum PageBG {
  PLANS = "PLANS",
  PLANS_WHITE = "PLANS_WHITE",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  QUESTIONNAIRE_WHITE = "QUESTIONNAIRE_WHITE",
  PRESALE_GUIDE = "PRESALE_GUIDE",
  CANCALATION = "CANCALATION",
  CANCEL_PROMO = "CANCEL_PROMO",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
}

interface IProps {
  pageBG: PageBG;
  children: React.ReactNode;
}

const Layout: FC<IProps> = ({ children, pageBG }) => {
  const getClassByPage = () => {
    switch (pageBG) {
      case PageBG.PLANS:
        return styles.plansBg;
      case PageBG.PLANS_WHITE:
        return styles.plansBgWhite;
      case PageBG.PAYMENT_SUCCESS:
        return styles.paymentSuccessBg;
      case PageBG.QUESTIONNAIRE:
        return styles.questionnaireBg;
      case PageBG.PRESALE_GUIDE:
        return styles.presaleGuideBg;
      case PageBG.QUESTIONNAIRE_WHITE:
        return styles.questionnaireWhiteBg;
      case PageBG.CANCALATION:
        return styles.cancel;
      case PageBG.CANCEL_PROMO:
        return styles.cancelPromo;
      case PageBG.OFFER_ACCEPTED:
        return styles.cancelOfferAccepted;
      default:
        return styles.plansBg;
    }
  };

  return (
    <div id="wrapper" className={classNames(getClassByPage(), styles.wrapper)}>
      <main className={styles.layout}>{children}</main>
    </div>
  );
};

export default Layout;
