import { FC } from "react";
import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import csx from "classnames";

interface IQuestionnaireTitle {
  title: string;
  description: string;
}

const QuestionnaireTitle: FC<IQuestionnaireTitle> = ({
  title,
  description,
}) => {
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  return (
    <div className={styles.wrapper}>
      <h2 className={csx(styles.title, { [styles.isWhite]: isWhite })}>
        {title}
      </h2>
      {description && (
        <h2 className={csx(styles.description, { [styles.isWhite]: isWhite })}>
          {description}
        </h2>
      )}
    </div>
  );
};

export default QuestionnaireTitle;
