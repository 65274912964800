import { useEffect, useState } from "react";
import { api, ApiPaths } from "./../api";
import { useLocation } from "react-router-dom";

export interface IPlan {
  id: string;
  braintree_id: string;
  plan_name: string;
  description: string;
  total: string;
  total_crossed: string;
  offer: string;
  duration: string;
  amount_per_period: string;
  period: string;
  price_per_day: string;
  is_most_popular: boolean;
  published: boolean;
  paywall: string;
  deeplink: string;
  paddle_discount_id: string;
  order: number;
  has_trial_period?: boolean;
}

const usePlans = () => {
  const [plans, setPlans] = useState<null | IPlan[]>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pw = queryParams.get("pw");

  const getPlansRequest = async (pw: string) => {
    setLoading(true);
    const response = await api.get(
      ApiPaths.BRAINTREE_PLANS + (pw ? `?pw=${pw}` : "")
    );
    const {
      data: { plans: plansResponse },
    } = response;

    setPlans(plansResponse);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (plans === null) {
        getPlansRequest(pw || "");
      }
    })();
  }, [plans, pw]); // eslint-disable-line react-hooks/exhaustive-deps

  return { plans, loading, getPlansRequest };
};

export default usePlans;
