import { FC } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import cls from "classnames";

interface IProps {
  text: string;
  stars: number;
  userName: string;
}

const Reviews: FC<IProps> = ({ text, stars, userName }) => {
  const starsCount = Array.from({ length: stars }, (_, i) => i);
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  return (
    <>
      <div className={cls(styles.reviews, { [styles.isWhite]: isWhite })}>
        <p className={styles.text}>{text}</p>
        <div className={styles.starsBlock}>
          <span className={styles.stars}>
            {starsCount.map((_, index) => {
              return (
                <Icon
                  type={IconTypes.STAR}
                  color="#ffa621"
                  size={12}
                  key={index}
                />
              );
            })}
          </span>
          <span className={styles.userName}>{userName}</span>
        </div>
      </div>
    </>
  );
};

export default Reviews;
