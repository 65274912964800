import { FC } from "react";
import { ReactComponent as Checked } from "./../../../assets/svg/checkMarked.svg";
import SubTitle from "../SubTitle";

import styles from "./styles.module.scss";

interface IBeneifts {
  benefits_extended: {
    title: string;
    description: string;
  }[];
}

const BenefitsExtended: FC<IBeneifts> = ({ benefits_extended }) => (
  <>
    <div className={styles.benefits}>
      <SubTitle text="THE BENEFITS YOU WILL RECEIVE:" />
      <div className={styles.benefitsBlock}>
        {benefits_extended.map((item, index) => (
          <div className={styles.benefit} key={index}>
            <div className={styles.title}>
              <Checked />
              {item.title}
            </div>
            <div className={styles.description}>{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  </>
);

export default BenefitsExtended;
