import { FC } from "react";

import styles from "./styles.module.scss";

interface IProps {
  text: string;
}

const SubTitle: FC<IProps> = ({ text }) => {
  return <h2 className={styles.subTitle}>{text}</h2>;
};

export default SubTitle;
