import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import useLazyGoogleTag from "./app/hooks/useLazyGoogleTag";

// import { IntercomProvider } from "react-use-intercom";
import PresaleGuide from "./app/pages/PresaleGuide";
import Plans from "./app/pages/Plans";
import PaymentSuccess from "./app/pages/PaymentSuccess";
import Packs from "./app/pages/Packs";
import ChatBot from "./app/pages/ChatBot";
import Questionnaire from "./app/pages/Questionnaire";
import Sentry from "./Sentry";
import FacebookPixel from "./FacebookPixel";
import Cancel from "./app/pages/Cancel";
import TikTokPixel from "./TikTokPixel";
import "./i18n";

// const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;

declare global {
  interface Window {
    ttq: any;
  }
}

function App() {
  useLazyGoogleTag();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <>Root -2</>,
    },
    {
      path: "/plans",
      element: <Plans />,
    },
    {
      path: "/payment-success",
      element: <PaymentSuccess />,
    },
    {
      path: "/packs",
      element: <Packs />,
    },
    {
      path: "/chat-bot",
      element: <ChatBot />,
    },
    {
      path: "/chat-bot/:bot_id",
      element: <ChatBot />,
    },
    {
      path: "/questionnaire",
      element: <Questionnaire />,
    },
    {
      path: "/presale-guide",
      element: <PresaleGuide />,
    },
    {
      path: "/cancel",
      element: <Cancel />,
    },
  ]);

  return (
    <>
      {window.location.pathname !== "/chat-bot" && (
        <>
          <Sentry />
          <FacebookPixel />
          <TikTokPixel />
        </>
      )}

      <RouterProvider router={router} />
      {/* <Fullstory /> */}
    </>
  );
}

export default App;
