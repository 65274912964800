import axios from "axios";
const HOST = "https://content-dev.the.coach";

const configureApi = (baseURL?: string) => axios.create({ baseURL });

export enum ApiPaths {
  "BRAINTREE_PLANS" = "braintree_plans/",
  "BRAINTREE_TOKEN" = "braintree_token/",
  "BRAINTREE_SUBSCRIPTION_NOAUTH" = "braintree_subscription_noauth/",
  "SEND_EMAIL" = "deeplink/save_email/",
  "PRE_SALE" = "braintree_subscription_upsell/",
  "CHAT_BOT" = "chatwithcoach/",
  "CHAT_BOT_START" = "/api/v1/aicoach/start",
  "QUESTIONNAIRE_SUBMIT" = "/api/v1/onboarding_questions/",
}

const api = configureApi(HOST);

export { api };
