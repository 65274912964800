import { FC } from "react";
import styles from "./styles.module.scss";
import csx from "classnames";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";

interface IQuestionnaireProgressBar {
  filled: number;
}

const QuestionnaireProgressBar: FC<IQuestionnaireProgressBar> = ({
  filled,
}) => {
  const theme = getQuestionnaireTheme();
  const isThemeWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  return (
    <div className={csx(styles.wrapper, { [styles.isWhite]: isThemeWhite })}>
      <div className={styles.filled} style={{ width: `${filled}%` }}></div>
    </div>
  );
};

export default QuestionnaireProgressBar;
