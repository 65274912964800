import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS, IUserData } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import { api, ApiPaths } from "../../api";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";

interface ICancelStepCanceled {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
  userData?: IUserData;
  user_id?: string;
}

const CancelStepCanceled: FC<ICancelStepCanceled> = ({
  onNextStep,
  onBackToApp,
  userData,
  user_id,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");
  const isES = lng === "es";

  const { t } = useTranslation();
  const [offerSubscriptionLoading, setOfferSubscriptionLoading] =
    useState<boolean>(false);

  const accseptTheOffer = async () => {
    amplitude.track({
      event_type: "Cancel_accept_second_SO_clicked",
      user_id: user_id,
    });

    setOfferSubscriptionLoading(true);
    try {
      const formData = new FormData();
      formData.append("userid", user_id ?? "");
      formData.append("email", userData?.email ?? "");
      formData.append(
        "braintree_user_id",
        userData?.subscription.amplitude.braintree_user_id ?? ""
      );
      formData.append("category", "Final Offer 1M4.99 Accepted");
      formData.append(
        "comment",
        `User accepted offer Final Offer 1M4.99 Accepted

    email: ${userData?.email}

    Amplitude ID: ${user_id}

    braintree_id: ${userData?.subscription.amplitude.braintree_user_id}`
      );

      const offer_response = await api.post(
        ApiPaths.ACCEPT_CANCEL_OFFER,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (offer_response.status === 200) {
        onNextStep(CANCEL_STEPS.OFFER_ACCEPTED);
      }
    } catch (e: any) {
      console.log(e);
    }

    setOfferSubscriptionLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.close} onClick={onBackToApp}>
          <Icon type={IconTypes.CROSS} size={12} />
        </div>
      </div>
      <div className={styles.logo}></div>
      <div className={styles.title}>
        {t("cancel_flow_subscription_canceled")}
      </div>
      <div className={styles.content}>
        <div className={`${styles.block} ${isES && styles.esblock}`}>
          <div className={styles.sub_title}>
            {t("cancel_flow_value_as_com")}
          </div>
          <div className={styles.sub_title_bottom}>
            {t("cancel_flow_lowest_price")}
          </div>
        </div>

        <div className={styles.description}>
          {t("cancel_flow_restore_access_1")}
          <span> {t("cancel_flow_restore_access_2")} </span>
          {t("cancel_flow_restore_access_3")}
        </div>
        <div className={styles.buttons}>
          <button className={styles.buttonStay} onClick={accseptTheOffer}>
            {offerSubscriptionLoading
              ? t("cancel_flow_loading")
              : t("cancel_flow_accept_offer_button")}
          </button>
          <button className={styles.buttonProceed} onClick={onBackToApp}>
            {t("cancel_flow_return_to_profile")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelStepCanceled;
