import styles from "./styles.module.scss";
import star from "./../../../../assets/Vector.png";
import { ReactNode } from "react";

interface IProps {
  name: string;
  raiting: number;
  describtion: string;
}

function SlideItemBlock({ name, raiting, describtion }: IProps) {
  const starts: ReactNode[] = [];

  for (let i = 0; i < raiting; i++) {
    starts.push(<img src={star} alt="star" key={i} />);
  }

  return (
    <div className={styles.block}>
      <h3 className={styles.name}>{name}</h3>
      <div className={styles.ratingSatrs}>{starts.map((item) => item)}</div>
      <p className={styles.describtion}>{describtion}</p>
    </div>
  );
}

export default SlideItemBlock;
