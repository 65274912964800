import { FC, useEffect } from "react";
import Divider from "../Divider";
import Icon, { IconTypes } from "../Icon";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  toggler: () => void;
  isOpen: boolean;
  isPaymentMenu?: boolean;
}

const Menu: FC<IProps> = ({ toggler, isOpen, isPaymentMenu }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={!isPaymentMenu ? styles.wrapper : styles.paymentWrapper}>
      <div className={styles.container}>
        <span onClick={toggler}>
          <Icon
            type={IconTypes.CROSS}
            color={isPaymentMenu ? "#fff" : "#00000"}
            size={26}
          />
        </span>
        <nav className={styles.menu}>
          <Divider bottom={0} top={0} isMenu />
          <ul className={styles.menulist}>
            <li>
              <a href="https://vamapps.com/privacy_policy">
                {t("paywall_menu_1")}
              </a>
            </li>
            <Divider bottom={0} top={0} isMenu />
            <li>
              <a href="https://vamapps.com/terms_of_service">
                {t("paywall_menu_2")}
              </a>
            </li>
          </ul>
          <Divider bottom={10} top={0} isMenu />
        </nav>
      </div>
    </div>
  );
};

export default Menu;
