import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import Icon, { IconTypes } from "../Icon";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";

interface ICancelStepQuestion {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
  user_id?: string;
}

const CancelStepQuestion: FC<ICancelStepQuestion> = ({
  onNextStep,
  onBackToApp,
  user_id,
}) => {
  const { t } = useTranslation();
  const [activeQuestion, setActiveQUestion] = useState<number>();

  const onQuestionSelect = (question: number) => {
    setActiveQUestion(question);
  };

  const onNextStepByQuestion = () => {
    switch (activeQuestion) {
      case 0:
        amplitude.track({
          event_type: "Cancel_reason_open",
          event_properties: {
            answer: "I don't see the results",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.PROGRESS);
        break;
      case 1:
        amplitude.track({
          event_type: "Cancel_reason_open",
          event_properties: {
            answer: "Too expensive",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.SAVINGS);
        break;
      case 2:
        amplitude.track({
          event_type: "Cancel_reason_open",
          event_properties: {
            answer: "I’ll forget to cancel later",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.REMINDER);
        break;
      case 3:
        amplitude.track({
          event_type: "Cancel_reason_open",
          event_properties: {
            answer: "Other",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.FEEDBACK);

        break;
      default:
        amplitude.track({
          event_type: "Cancel_reason_open",
          event_properties: {
            answer: "Not selcted",
          },
          user_id: user_id,
        });
        onNextStep(CANCEL_STEPS.REASON);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.header}>
          <div className={styles.close} onClick={onBackToApp}>
            <Icon type={IconTypes.CROSS} size={12} />
          </div>
        </div>
        <div className={styles.title}>
          {t("cancel_flow_help_understand_leaving")}
        </div>
        <div className={styles.questions}>
          <div
            className={`${styles.question} ${activeQuestion === 0 && styles.active}`}
            onClick={() => onQuestionSelect(0)}
          >
            <div className={styles.label}>
              {t("cancel_flow_reason_no_results")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
          <div
            className={`${styles.question} ${activeQuestion === 1 && styles.active}`}
            onClick={() => onQuestionSelect(1)}
          >
            <div className={styles.label}>
              {t("cancel_flow_reason_expensive")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>

          <div
            className={`${styles.question} ${activeQuestion === 2 && styles.active}`}
            onClick={() => onQuestionSelect(2)}
          >
            <div className={styles.label}>
              {t("cancel_flow_reason_forget_cancel")}
            </div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>

          <div
            className={`${styles.question} ${activeQuestion === 3 && styles.active}`}
            onClick={() => onQuestionSelect(3)}
          >
            <div className={styles.label}>{t("cancel_flow_reason_other")}</div>
            <div className={styles.icon}>
              <Icon type={IconTypes.CHECK} size={16} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bootom}>
        <button className={styles.buttonStay} onClick={onBackToApp}>
          {t("cancel_flow_dont_leave")}
        </button>
        <button className={styles.buttonProceed} onClick={onNextStepByQuestion}>
          {t("cancel_flow_continue")}
        </button>
      </div>
    </div>
  );
};

export default CancelStepQuestion;
