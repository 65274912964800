import "./App.css";
import * as Sentry from "@sentry/react";

function SentryLoader() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    ignoreErrors: [
      "Network Error", // Generic error to ignore
      /^Request failed with status code \d+$/, // Regex pattern
    ],
  });
  return null;
}

export default SentryLoader;
