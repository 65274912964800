import { FC } from "react";
import styles from "./styles.module.scss";
import { CANCEL_STEPS } from "../../pages/Cancel";
import cards_img from "./../../../assets/cancel/cards.png";
import cards_img_es from "./../../../assets/cancel/cards_es.png";
import off30_img from "./../../../assets/cancel/off30.png";
import off30_img_es from "./../../../assets/cancel/off30_es.png";
import Icon, { IconTypes } from "../Icon";
import { useTranslation } from "react-i18next";

interface ICancelStepPromo {
  onNextStep: (step: CANCEL_STEPS) => void;
  onBackToApp: () => void;
}

const CancelStepPromo: FC<ICancelStepPromo> = ({ onNextStep, onBackToApp }) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");
  const isES = lng === "es";

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <div className={styles.bannerLogo}></div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{t("cancel_flow_dont_give_up")}</div>
        <div className={styles.description}>
          {t("cancel_flow_little_effort")}
        </div>
        <div className={styles.block}>
          <span>&gt;75%</span> {t("cancel_flow_coach_improvement_1")}{" "}
          <span>{t("cancel_flow_coach_improvement_2")}</span>{" "}
          {t("cancel_flow_coach_improvement_3")}
        </div>
        <div className={styles.sub_title}>{t("cancel_flow_try_programs")}</div>
        <img
          alt="cards"
          src={isES ? cards_img_es : cards_img}
          className={styles.img}
        />
        {/* <button
          className={styles.button}
          onClick={() => {
            window.location.href = "thecoach://tab/PROGRAM";
          }}
        >
          SEE ALL
        </button> */}
        <div className={styles.sub_title}>
          {t("cancel_flow_speed_up_progress")}
        </div>
        <img
          alt="cards"
          src={isES ? off30_img_es : off30_img}
          className={styles.img}
        />
        <button
          className={styles.button}
          onClick={() => {
            window.location.href =
              "https://shop.the.coach/?utm_source=app_cancel&utm_medium=app_cancel&utm_campaign=app_cancel";
          }}
        >
          {t("cancel_flow_go_to_shop")}
        </button>
        <div className={styles.steps_title}>
          {t("cancel_flow_stopping_subscription_1")}{" "}
          <span>{t("cancel_flow_stopping_subscription_2")}</span>
        </div>
        <div className={styles.points}>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>
              {t("cancel_flow_lose_daily_plan")}
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>{t("cancel_flow_lose_progress")}</div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>{t("cancel_flow_lose_lessons")}</div>
          </div>
          <div className={styles.point}>
            <div className={styles.check}>
              <Icon type={IconTypes.CHECK} size={20} />
            </div>
            <div className={styles.text}>
              {t("cancel_flow_lose_motivation")}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <button className={styles.buttonStay} onClick={onBackToApp}>
            {t("cancel_flow_give_another_try")}
          </button>
          <button
            className={styles.buttonProceed}
            onClick={() => onNextStep(CANCEL_STEPS.QUESTION)}
          >
            {t("cancel_flow_proceed_cancellation")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelStepPromo;
