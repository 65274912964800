import styles from "./styles.module.scss";
import logo from "./../../../assets/logos/Logo_img.png";
import {
  useForm,
  SubmitHandler,
  Controller,
  RegisterOptions,
} from "react-hook-form";
import Input from "./Input";
import Radio from "./Radio";
import * as Sentry from "@sentry/react";
import { api, ApiPaths } from "../../api";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface IFormInput {
  fullName: string;
  country: string;
  city: string;
  address: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  pack: string;
}

interface IIsPaymantSuccessWithPreSale {
  nonce: string;
  braintree_user_id: string;
}

interface IPack {
  deal: string;
  freeDelivery: boolean;
  mostPopular: boolean;
  title: string;
  totalPrevPrice: string;
  totalPrice: string;
  price: string;
  item: string;
}

interface Iprops {
  onClose: () => void;
}

interface IinputItem {
  name: keyof IFormInput;
  rules:
    | Omit<
        RegisterOptions<IFormInput>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined;
  label: string;
  placeholder: string;
}
const inputs: IinputItem[] = [
  {
    name: "fullName",
    rules: {
      required: "Please enter full name",
    },
    label: "Full name",
    placeholder: "Name Surname",
  },
  {
    name: "country",
    rules: {
      required: "Please enter country",
    },
    label: "Country",
    placeholder: "United States",
  },
  {
    name: "city",
    rules: {
      required: "Please enter city",
    },
    label: "city",
    placeholder: "Washington",
  },
  {
    name: "address",
    rules: {
      required: "Please enter address",
    },
    label: "Address",
    placeholder: "Street, apt, suite, building, ets.",
  },
  {
    name: "state",
    rules: {},
    label: "State (optional)",
    placeholder: "Columbia",
  },
  {
    name: "zip",
    rules: {
      required: "Please enter zip code",
    },
    label: "Zip Code",
    placeholder: "555555",
  },
  {
    name: "phone",
    rules: {
      required: "Please enter phone number",
    },
    label: "Phone number",
    placeholder: "33 333 333 333",
  },
  {
    name: "email",
    rules: {
      required: "Please enter email",
      pattern: {
        // eslint-disable-next-line
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        message: "Please enter valid email",
      },
    },
    label: "enter your email",
    placeholder: "washington@usa.com",
  },
];

const packs: IPack[] = [
  {
    deal: "6x bottle",
    freeDelivery: true,
    mostPopular: false,
    title: "6 Month Pack",
    totalPrevPrice: "$179.94",
    totalPrice: "$149.99",
    price: "$ 24.99",
    item: "bottle",
  },
  {
    deal: "3x bottle",
    freeDelivery: true,
    mostPopular: true,
    title: "3 Month Pack",
    totalPrevPrice: "$89.97",
    totalPrice: "$83.97",
    price: "$ 27.99",
    item: "bottle",
  },
  {
    deal: "1x bottle",
    freeDelivery: false,
    mostPopular: false,
    title: "1 Month Pack",
    totalPrevPrice: "",
    totalPrice: "$29.99",
    price: "$ 29.99",
    item: "bottle",
  },
];

const PresaleForm = ({ onClose }: Iprops) => {
  const payment_email = localStorage.getItem("payment_email");
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    resetField,
  } = useForm<IFormInput>({
    defaultValues: {
      fullName: "",
      country: "",
      city: "",
      address: "",
      state: "",
      zip: "",
      phone: "",
      email: payment_email || "",
      pack: "$149.99",
    },
  });

  const [isPaymentSuccessWithPreSale, setIsPaymentSuccessWithPreSale] =
    useState<IIsPaymantSuccessWithPreSale | undefined>();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      const [packData] = packs.filter((pack) => pack.totalPrice === data.pack);
      const response = await api.post(
        ApiPaths.PRE_SALE,
        JSON.stringify({
          ...data,
          ...isPaymentSuccessWithPreSale,
          packData: packData,
        })
      );
      if (response.status === 200) {
        navigate("/payment-success");
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    const storageItem = localStorage.getItem("isPaymentSuccessWithPreSale");
    if (storageItem) {
      setIsPaymentSuccessWithPreSale(
        JSON.parse(
          storageItem ? storageItem : ""
        ) as IIsPaymantSuccessWithPreSale
      );
    }
  }, []);

  return (
    <div className={styles.content}>
      <span className={styles.closeIcon} onClick={onClose}>
        &#x2715;
      </span>

      <section>
        <img src={logo} alt="logo" className={styles.logo} />
      </section>
      <section>
        <h2 className={styles.subTitle}>You’re On The Right Way! </h2>
        <p className={styles.description}>Shipping info</p>
      </section>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className={styles.inputs}>
            {inputs.map((item) => (
              <div
                key={item.name}
                className={styles[item.name] + " " + styles.container}
              >
                <Controller
                  name={item.name}
                  control={control}
                  rules={item.rules}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        onChange={onChange}
                        type={item.name === "phone" ? "number" : "text"}
                        value={value}
                        label={item.label}
                        placeholder={item.placeholder}
                        onReset={() => resetField(item.name)}
                        errors={!!errors[item.name]}
                        errorsMessage={errors[item.name]?.message ?? ""}
                        name={item.name}
                      />
                    );
                  }}
                />
              </div>
            ))}
          </section>
          <p className={styles.paragraph}>
            Payment will be processed automatically based on the billing
            information you provided earlier.
          </p>
          <p className={styles.description}>Choose your pack</p>
          {packs.map((item, index) => (
            <div key={index}>
              <Controller
                name={"pack"}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Radio {...item} value={value} onChange={onChange} />
                )}
              />
            </div>
          ))}
          <button
            className={styles.primery}
            disabled={isSubmitting}
            type="submit"
          >
            PLACE THE ORDER
          </button>
        </form>
      </section>
    </div>
  );
};

export default PresaleForm;
