export const setCardFieldsValidation = ({
  event,
  setIsFieldsValid,
}: {
  event: braintree.HostedFieldsEvent;
  setIsFieldsValid: React.Dispatch<
    React.SetStateAction<{
      number: null | string;
      expirationDate: null | string;
      cvv: null | string;
    }>
  >;
}) => {
  const initFieldsValidation: {
    number: null | string;
    expirationDate: null | string;
    cvv: null | string;
  } = {
    number: null,
    cvv: null,
    expirationDate: null,
  };

  if (event.fields.number.isEmpty) {
    initFieldsValidation.number = "Please fill out a card number.";
  } else if (!event.fields.number.isValid) {
    initFieldsValidation.number = "This card number is not valid.";
  }

  if (event.fields.cvv.isEmpty) {
    initFieldsValidation.cvv = "Please fill out a CVV.";
  } else if (!event.fields.cvv.isValid) {
    initFieldsValidation.cvv = "This security code is not valid.";
  }

  if (event.fields.expirationDate.isEmpty) {
    initFieldsValidation.expirationDate = "Please fill out an expiration date.";
  } else if (!event.fields.expirationDate.isValid) {
    initFieldsValidation.expirationDate = "This expiration date is not valid.";
  }

  setIsFieldsValid(initFieldsValidation);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
