import { FC } from "react";
import styles from "./styles.module.scss";
import { IQuestionnaireData } from "../../pages/Questionnaire";
import QuestionnaireSingle from "../QuestionnaireSingle";
import QuestionnaireInformation from "../QuestionnaireInformation";
import QuestionnaireInformationCustom from "../QuestionnaireInformationCustom";
import QuestionnaireSingleSquare from "../QuestionnaireSingleSquare";
import QuestionnaireEmail from "../QuestionnaireEmail";

interface IQuestionnaireContent {
  question: IQuestionnaireData;
  onNextQuestion: (answer: string) => void;
}

const QuestionnaireContent: FC<IQuestionnaireContent> = ({
  question,
  onNextQuestion,
}) => {
  const getQuestionContent = () => {
    const { type } = question;

    switch (type) {
      case "single":
        return (
          <QuestionnaireSingle
            question={question}
            onNextQuestion={onNextQuestion}
          />
        );
      case "information":
        return (
          <QuestionnaireInformation
            question={question}
            onNextQuestion={onNextQuestion}
          />
        );
      case "information-custom":
        return (
          <QuestionnaireInformationCustom
            question={question}
            onNextQuestion={onNextQuestion}
          />
        );
      case "single-square":
        return (
          <QuestionnaireSingleSquare
            question={question}
            onNextQuestion={onNextQuestion}
          />
        );
      case "email":
        return <QuestionnaireEmail onNextQuestion={onNextQuestion} />;
      default:
        return <></>;
    }
  };

  return <div className={styles.wrapper}>{getQuestionContent()}</div>;
};

export default QuestionnaireContent;
