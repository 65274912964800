import React, {
  // useEffect,
  FC,
  useRef,
  // useState,
  FormEventHandler,
} from "react";
// import dropin from "braintree-web-drop-in";
// import {
//   createEmailInput,
//   setEmailError,
// } from "../../helpers/createEmailInput";
import styles from "./styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
// import * as Sentry from "@sentry/react";
// import useBackendLog from "../../hooks/useBackendLog";
import { CardElement, useRecurly } from "@recurly/react-recurly";
import { api, ApiPaths } from "../../api";

interface IProps {
  token: string;
  onPaymentMethodReceived: (nonce: string) => void;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
  recaptchaRef?: React.MutableRefObject<ReCAPTCHA | null>;
}

const RecurlyCard: FC<IProps> = () =>
  // {
  // token,
  // onPaymentMethodReceived,
  // setIsPaymentProgress,
  // recaptchaRef,
  // }
  {
    const formRef = useRef<HTMLFormElement>(null);
    const recurly = useRecurly();

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
      event.preventDefault();

      if (formRef.current) {
        recurly.token(formRef.current, (err, token) => {
          if (err) {
            // handle error
          } else {
            (async () => {
              await api.post(
                ApiPaths.RECURLY_SUBMIT,
                JSON.stringify({
                  plan_id: "rec_7day_male_6_99_then_1m_38_99",
                  nonce: token.id,
                  email:
                    localStorage.getItem("payment_email") ||
                    "testemail123@test.test",
                }),
                {
                  headers: {
                    Referer: window.location.href,
                  },
                }
              );
            })();
          }
        });
      }
    };

    return (
      <form ref={formRef} className={styles.form} onSubmit={handleSubmit}>
        <input type="text" data-recurly="first_name" placeholder="First name" />
        <input type="text" data-recurly="last_name" placeholder="Last name" />
        <CardElement />

        <button className={styles.pay}>Pay</button>
      </form>
    );
  };

export default RecurlyCard;
