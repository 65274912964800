import { FC, useEffect, useState } from "react";
import braintree, { Client } from "braintree-web";
import styles from "./styles.module.scss";
import { ReactComponent as GooglePay } from "./../../../assets/svg/google-pay.svg";
import * as Sentry from "@sentry/react";
import { sentryLog } from "../../helpers/sentryLog";
import useBackendLog from "../../hooks/useBackendLog";

interface IProps {
  braintreeClientInstance: Client;
  onPaymentMethodReceived: (nonce: string) => void;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
  total: string;
}

function loadGooglePayScript(onLoadCallback: () => void) {
  const script = document.createElement("script");
  script.src = "https://pay.google.com/gp/p/js/pay.js";
  script.async = true;
  script.onload = onLoadCallback;
  document.body.appendChild(script);
}

const BraintreeGooglePay: FC<IProps> = ({
  braintreeClientInstance,
  onPaymentMethodReceived,
  setIsPaymentProgress,
  total,
}) => {
  const [paymentInstance, setPaymentInstance] =
    useState<braintree.GooglePayment | null>(null);

  const { onBackendLog } = useBackendLog();

  useEffect(() => {
    if (braintreeClientInstance) {
      braintree.googlePayment.create(
        {
          client: braintreeClientInstance,
          googlePayVersion: 2, // Google Pay API version
          googleMerchantId: "BCR2DN4TWKINDCA2",
        },
        (err, googlePaymentInstance) => {
          if (err) {
            Sentry.captureException(err);
            return;
          }

          if (googlePaymentInstance) {
            setPaymentInstance(googlePaymentInstance);
          }
        }
      );
    }
  }, [braintreeClientInstance]);

  const onGoolgeButtonClick = async () => {
    onBackendLog({
      type: "payment_form_loaded",
    });

    setIsPaymentProgress(true);
    loadGooglePayScript(async () => {
      if (paymentInstance) {
        const paymentsClient = new google.payments.api.PaymentsClient({
          environment: "TEST",
        });

        const paymentData = {
          transactionInfo: {
            currencyCode: "USD",
            totalPriceStatus: "FINAL",
            totalPrice: total,
          },
        };
        try {
          const paymentDataRequest =
            await paymentInstance.createPaymentDataRequest(paymentData);

          const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
          cardPaymentMethod.parameters.billingAddressRequired = true;
          cardPaymentMethod.parameters.billingAddressParameters = {
            format: "FULL",
            phoneNumberRequired: true,
          };

          paymentsClient
            .loadPaymentData(paymentDataRequest)
            .then(function (paymentData) {
              paymentInstance.parseResponse(
                paymentData,
                function (err, result) {
                  onPaymentMethodReceived(result.nonce);
                }
              );
            })
            .catch(function (err) {
              setIsPaymentProgress(false);
              Sentry.captureException(err, { data: paymentDataRequest });
            });
        } catch (err) {
          Sentry.captureException(err);
        }
      } else {
        sentryLog("Google pay instance do not exists after button click", {});
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={onGoolgeButtonClick}>
        <GooglePay />
      </button>
    </div>
  );
};

export default BraintreeGooglePay;
