import { FC } from "react";
import { ReactComponent as Checked } from "./../../../assets/svg/checkMarked.svg";
import SubTitle from "../SubTitle";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface IBeneifts {
  benefits: string[];
}

const Benefits: FC<IBeneifts> = ({ benefits }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.benefits}>
        <SubTitle text={t("paywall_benefits_header")} />
        <div className={styles.benefitsBlock}>
          {benefits.map((item, index) => (
            <span key={index}>
              <Checked />
              {t(item)}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default Benefits;
