import { useEffect } from "react";

const useLazyGoogleTag = () => {
  useEffect(() => {
    const loadGoogleTag = async () => {
      const ReactGA = (await import("react-ga4")).default;
      ReactGA.initialize(process.env.REACT_APP_GTAG as string);
    };

    const timeout = setTimeout(() => {
      loadGoogleTag();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
};

export default useLazyGoogleTag;
