import { FC } from "react";
import styles from "./styles.module.scss";

interface IProps {
  small?: boolean;
}

const Spinner: FC<IProps> = ({ small }) => {
  return (
    <div className={styles.wrapper}>
      <div className={!small ? styles.loading : styles.small}>
        <div className={!small ? styles.spinner : styles.smallSpinner}></div>
        {!small && "Loading"}
      </div>
    </div>
  );
};

export default Spinner;
