import "./App.css";
import ReactPixel from "react-facebook-pixel";

function FacebookPixel() {
  const queryParams = new URLSearchParams(window.location.search);
  const pixel_id = queryParams.get("pixel_id");
  const key = pixel_id || "620364752698958";

  ReactPixel.init(key);
  ReactPixel.pageView();
  return null;
}

export default FacebookPixel;
