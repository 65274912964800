import { IQuestionnaireData } from ".";
import yearOld18 from "./../../../assets/woman_flow/18-24.jpg";
import yearOld25 from "./../../../assets/woman_flow/25-34.jpg";
import yearOld35 from "./../../../assets/woman_flow/35-44.jpg";
import yearOld45 from "./../../../assets/woman_flow/45+.jpg";
import step2 from "./../../../assets/woman_flow/Step2_white.png";
import step6 from "./../../../assets/woman_flow/Step6.jpg";
import step14 from "./../../../assets/woman_flow/Step14.jpg";
import step22 from "./../../../assets/woman_flow/Step22_white.jpg";

export const woman_flow_qs: IQuestionnaireData[] = [
  {
    type: "single-square",
    badge: "WELCOME",
    title: "IT’S TIME TO COME FIRST",
    description: "Choose your age to start:",
    questions: [
      {
        value: "q1",
        text: "18-25",
        img: yearOld18,
      },
      {
        value: "q2",
        text: "26-35",
        img: yearOld25,
      },
      {
        value: "q3",
        text: "36-45",
        img: yearOld35,
      },
      {
        value: "q4",
        text: "46+",
        img: yearOld45,
      },
    ],
  },
  {
    type: "information-custom",
    badge: "JOIN US",
    title: "OVER 1M PEOPLE HAVE ALREADY USED OUR PROGRAM",
    description: "",
    Component: () => (
      <>
        <img
          style={{
            width: "100%",
            borderRadius: "8px",
          }}
          src={step2}
          alt="text"
        />
      </>
    ),
  },
  {
    type: "single",
    badge: "Connection & Emotional Intimacy",
    title:
      "How emotionally connected do you feel with your partner during intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Deeply connected",
      },
      {
        value: "q2",
        text: "Somewhat connected",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Occasionally disconnected",
      },
      {
        value: "q5",
        text: "Often disconnected",
      },
    ],
  },
  {
    type: "single",
    badge: "Connection & Emotional Intimacy",
    title: "When was the last time you felt truly romantic with your partner?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Recently, we still keep the spark alive",
      },
      {
        value: "q2",
        text: "A while ago, but I miss it",
      },
      {
        value: "q3",
        text: "I don’t remember the last time",
      },
      {
        value: "q4",
        text: "I’ve never really felt that way",
      },
    ],
  },
  {
    type: "single",
    badge: "Connection & Emotional Intimacy",
    title:
      "How comfortable are you discussing your desires and needs with your partner?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very comfortable",
      },
      {
        value: "q2",
        text: "Somewhat comfortable",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "A bit uncomfortable",
      },
      {
        value: "q5",
        text: "Very uncomfortable",
      },
    ],
  },
  {
    type: "single",
    badge: "Connection & Emotional Intimacy",
    title:
      "Do you wish you and your partner had more open, judgment-free conversations about intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes, I’d love that",
      },
      {
        value: "q2",
        text: "Sometimes, but it’s hard to start",
      },
      {
        value: "q3",
        text: "No, we already talk openly",
      },
      {
        value: "q4",
        text: "I don’t think it would change anything",
      },
    ],
  },
  {
    type: "single",
    badge: "Connection & Emotional Intimacy",
    title: "How often do you feel truly present and engaged during intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always",
      },
      {
        value: "q2",
        text: "Most of the time",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Strengthen your connection",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Talking openly and feeling emotionally close to your partner can{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            boost your desire
          </b>{" "}
          and greatly{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            improve your intimate life.
          </b>
        </span>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          There are{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            simple techniques
          </b>{" "}
          that can help women build stronger relationships.
        </span>

        <img
          style={{
            width: "100%",
            marginTop: "12px",
            borderRadius: "8px",
          }}
          src={step14}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Satisfaction & Fulfillment",
    title: "How satisfied are you with your intimate life?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very satisfied",
      },
      {
        value: "q2",
        text: "Mostly satisfied",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Slightly dissatisfied",
      },
      {
        value: "q5",
        text: "Very dissatisfied",
      },
    ],
  },
  {
    type: "single",
    badge: "Satisfaction & Fulfillment",
    title: "What aspect of intimacy brings you the most pleasure?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Emotional connection",
      },
      {
        value: "q2",
        text: "Physical pleasure",
      },
      {
        value: "q3",
        text: "Novelty and adventure",
      },
      {
        value: "q4",
        text: "Deep conversations",
      },
      {
        value: "q5",
        text: "Sensual touch",
      },
    ],
  },
  {
    type: "single",
    badge: "Satisfaction & Fulfillment",
    title:
      "How often do you feel that your desires and pleasure are prioritized?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Satisfaction & Fulfillment",
    title: "How often do you experience pleasure on your own?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Every time I want to",
      },
      {
        value: "q2",
        text: "Often, but not always",
      },
      {
        value: "q3",
        text: "Rarely",
      },
      {
        value: "q4",
        text: "I don’t explore this much",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Feeling satisfied in your intimate life has a big impact",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          Knowing that you are desired and that{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            both you and your partner
          </b>{" "}
          genuinely want to bring each other{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            pleasure
          </b>{" "}
          is a key part of a happy and fulfilling intimate relationship.
        </span>

        <img
          style={{
            width: "100%",
            marginTop: "12px",
            borderRadius: "8px",
          }}
          src={step6}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Bright & Passionate Sex",
    title:
      "How often do you crave passionate, exciting experiences in the bedroom?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "All the time",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q5",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Bright & Passionate Sex",
    title: "When was the last time you had truly unforgettable sex?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Recently, and it was amazing",
      },
      {
        value: "q2",
        text: "A few months ago",
      },
      {
        value: "q3",
        text: "I can’t remember",
      },
      {
        value: "q4",
        text: "I don’t think I ever have",
      },
    ],
  },
  {
    type: "single",
    badge: "Bright & Passionate Sex",
    title: "What excites you most about intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Playfulness and fun",
      },
      {
        value: "q2",
        text: "Deep emotional bonding",
      },
      {
        value: "q3",
        text: "Trying new things",
      },
      {
        value: "q4",
        text: "Feeling desired",
      },
      {
        value: "q4",
        text: "Sensory pleasure",
      },
    ],
  },
  {
    type: "single",
    badge: "Bright & Passionate Sex",
    title:
      "Do you know the factors that increase or decrease your sexual desire?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes, very well",
      },
      {
        value: "q2",
        text: "Mostly",
      },
      {
        value: "q3",
        text: "A little",
      },
      {
        value: "q4",
        text: "Not much",
      },
      {
        value: "q4",
        text: "Not at all",
      },
    ],
  },
  {
    type: "single",
    badge: "Being the Best for Your Partner",
    title: "How confident do you feel in your ability to satisfy your partner?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Extremely confident",
      },
      {
        value: "q2",
        text: "Fairly confident",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "A bit insecure",
      },
      {
        value: "q4",
        text: "Very insecure",
      },
    ],
  },
  {
    type: "single",
    badge: "Being the Best for Your Partner",
    title:
      "What do you believe is the key to a fulfilling intimate relationship?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Understanding each other’s desires",
      },
      {
        value: "q2",
        text: "Keeping things exciting",
      },
      {
        value: "q3",
        text: "Building emotional closeness",
      },
      {
        value: "q4",
        text: "Clear communication",
      },
      {
        value: "q4",
        text: "Mutual respect and trust",
      },
    ],
  },
  {
    type: "single",
    badge: "Being the Best for Your Partner",
    title: "Do you enjoy taking the lead in intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q4",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Being the Best for Your Partner",
    title: "How would you rate your skills in bed?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "I’m amazing and I know it",
      },
      {
        value: "q2",
        text: "Pretty good, but there’s room to grow",
      },
      {
        value: "q3",
        text: "I’m not sure, I just go with the flow",
      },
      {
        value: "q4",
        text: "I have no idea",
      },
    ],
  },
  {
    type: "single",
    badge: "Being the Best for Your Partner",
    title: "Would you like to know how to reach climax first every time?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes, that sounds exciting",
      },
      {
        value: "q2",
        text: "Maybe, I’d like to learn more",
      },
      {
        value: "q3",
        text: "Not really, I prefer a different pace",
      },
      {
        value: "q4",
        text: "No, that’s not important to me",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Know yourself—it builds confidence",
    description: "",
    Component: () => (
      <div>
        <span
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "18px",
            color: "#141d19",
            lineHeight: "22.5px",
          }}
        >
          When you understand your desires, you gain{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            control.
          </b>{" "}
          And control brings confidence.{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            Confidence
          </b>{" "}
          is what truly attracts others. <br /> We’ll help you{" "}
          <b style={{ fontFamily: "New York Small Bold, Arial, sans-serif" }}>
            become your best self
          </b>{" "}
          for the people who matter!
        </span>

        <img
          style={{
            width: "100%",
            marginTop: "12px",
            borderRadius: "8px",
          }}
          src={step22}
          alt="text"
        />
      </div>
    ),
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title:
      "How comfortable are you guiding your partner in what brings you pleasure?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very comfortable",
      },
      {
        value: "q2",
        text: "Somewhat comfortable",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "A bit uncomfortable",
      },
      {
        value: "q4",
        text: "Very uncomfortable",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title: "How responsive is your partner to feedback about intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Always open and eager to learn",
      },
      {
        value: "q2",
        text: "Mostly open",
      },
      {
        value: "q3",
        text: "Sometimes open",
      },
      {
        value: "q4",
        text: "Rarely open",
      },
      {
        value: "q4",
        text: "Not open at all",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title: "Do you believe your partner truly understands your desires?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Completely",
      },
      {
        value: "q2",
        text: "Mostly",
      },
      {
        value: "q3",
        text: "Somewhat",
      },
      {
        value: "q4",
        text: "Not really",
      },
      {
        value: "q4",
        text: "Not at all",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title:
      "How often do you introduce new ideas or techniques to improve intimacy?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very often",
      },
      {
        value: "q2",
        text: "Often",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Rarely",
      },
      {
        value: "q4",
        text: "Never",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title:
      "If your partner’s performance doesn’t fully satisfy you, how do you handle it?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Openly communicate and guide him",
      },
      {
        value: "q2",
        text: "Drop hints and hope he notices",
      },
      {
        value: "q3",
        text: "Accept it as it is",
      },
      {
        value: "q4",
        text: "Avoid discussing it",
      },
      {
        value: "q4",
        text: "Feel frustrated but say nothing",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title:
      "What is the biggest challenge in teaching your partner what you like?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Fear of hurting his feelings",
      },
      {
        value: "q2",
        text: "Not knowing how to express it",
      },
      {
        value: "q3",
        text: "He doesn’t seem interested in learning",
      },
      {
        value: "q4",
        text: "I’m unsure about my own desires",
      },
      {
        value: "q4",
        text: "No challenges - I communicate openly",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title: "Have you ever faked satisfaction to avoid hurting his ego?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Never",
      },
      {
        value: "q2",
        text: "Rarely",
      },
      {
        value: "q3",
        text: "Sometimes",
      },
      {
        value: "q4",
        text: "Often",
      },
      {
        value: "q4",
        text: "Almost always",
      },
    ],
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title: "What would help you feel more empowered in guiding your partner?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "More knowledge about my own body",
      },
      {
        value: "q2",
        text: "Clearer communication skills",
      },
      {
        value: "q3",
        text: "A more open and receptive partner",
      },
      {
        value: "q4",
        text: "Confidence in speaking up",
      },
      {
        value: "q4",
        text: "A safe and judgment-free space",
      },
    ],
  },
  {
    type: "analyze",
    badge: "",
    title: "",
    description: "",
    Component: () => <></>,
  },
  {
    type: "single",
    badge: "Teach How to Perform",
    title: "Do you ever compare yourself to other women?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes, all the time",
      },
      {
        value: "q2",
        text: "Sometimes, but I try not to",
      },
      {
        value: "q3",
        text: "Rarely, I know my worth",
      },
      {
        value: "q4",
        text: "Confidence in speaking up",
      },
      {
        value: "q4",
        text: "Never, I’m confident in myself",
      },
    ],
  },
  {
    type: "single",
    badge: "About You",
    title: "Are you happy with your body?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Yes, I love and embrace it",
      },
      {
        value: "q2",
        text: "Mostly, but I have insecurities",
      },
      {
        value: "q3",
        text: "Not really, I struggle with self-image",
      },
      {
        value: "q4",
        text: "No, I wish I could change a lot",
      },
    ],
  },
  {
    type: "single",
    badge: "About You",
    title: "How important is improving your intimacy to you?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Very important",
      },
      {
        value: "q2",
        text: "Important",
      },
      {
        value: "q3",
        text: "Neutral",
      },
      {
        value: "q4",
        text: "Not very important",
      },
      {
        value: "q5",
        text: "Not important at all",
      },
    ],
  },
  {
    type: "single",
    badge: "About You",
    title: "What is the main goal would you like to achieve?",
    description: "",
    questions: [
      {
        value: "q1",
        text: "Greater connection with my partner",
      },
      {
        value: "q2",
        text: "Better understanding of myself",
      },
      {
        value: "q3",
        text: "More confidence and control",
      },
      {
        value: "q4",
        text: "Enhanced physical pleasure",
      },
    ],
  },
  // {
  //   type: "email",
  //   badge: "",
  //   title:
  //     "Enter your email to save your profile and improve your intimate life",
  //   description: "",
  //   Component: () => <></>,
  // },
  {
    type: "information-custom",
    badge: "GOOD TO KNOW",
    title: "Science backed approach approved by our users",
    description: "",
    Component: () => (
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          <div
            style={{
              width: "calc(50% - 4px)",
              backgroundColor: "#eff4f2",
              borderRadius: "8px",
              padding: "24px 8px",
            }}
          >
            <div
              style={{
                fontFamily: "New York Small Bold, Arial, sans-serif",
                fontSize: "34px",
                color: "#1c4436",
                textAlign: "center",
              }}
            >
              89%
            </div>
            <div
              style={{
                display: "block",
                marginTop: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "14px",
                color: "#141d19",
                lineHeight: "16.5px",
                textAlign: "center",
              }}
            >
              Reported having more pleasurable sex
            </div>
          </div>
          <div
            style={{
              width: "calc(50% - 4px)",
              backgroundColor: "#eff4f2",
              borderRadius: "8px",
              padding: "24px 8px",
            }}
          >
            <div
              style={{
                fontFamily: "New York Small Bold, Arial, sans-serif",
                fontSize: "34px",
                color: "#1c4436",
                textAlign: "center",
              }}
            >
              74%
            </div>
            <div
              style={{
                display: "block",
                marginTop: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "14px",
                color: "#141d19",
                lineHeight: "16.5px",
                textAlign: "center",
              }}
            >
              Experienced a boost in libido
            </div>
          </div>
          <div
            style={{
              width: "calc(50% - 4px)",
              backgroundColor: "#eff4f2",
              borderRadius: "8px",
              padding: "24px 8px",
            }}
          >
            <div
              style={{
                fontFamily: "New York Small Bold, Arial, sans-serif",
                fontSize: "34px",
                color: "#1c4436",
                textAlign: "center",
              }}
            >
              95%
            </div>
            <div
              style={{
                display: "block",
                marginTop: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "14px",
                color: "#141d19",
                lineHeight: "16.5px",
                textAlign: "center",
              }}
            >
              Reported more confidence in bed
            </div>
          </div>
          <div
            style={{
              width: "calc(50% - 4px)",
              backgroundColor: "#eff4f2",
              borderRadius: "8px",
              padding: "24px 8px",
            }}
          >
            <div
              style={{
                fontFamily: "New York Small Bold, Arial, sans-serif",
                fontSize: "34px",
                color: "#1c4436",
                textAlign: "center",
              }}
            >
              65%
            </div>
            <div
              style={{
                display: "block",
                marginTop: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "14px",
                color: "#141d19",
                lineHeight: "16.5px",
                textAlign: "center",
              }}
            >
              Saw an increase in overall well-being
            </div>
          </div>
        </div>
        <div
          style={{
            display: "block",
            marginTop: "16px",
            fontFamily: "New York Small, Arial, sans-serif",
            fontSize: "14px",
            color: "#141d19",
            lineHeight: "16.5px",
            textAlign: "center",
          }}
        >
          *Based on users' self-report after 4-6 weeks
        </div>
      </div>
    ),
  },
];
