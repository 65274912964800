import { useTranslation } from "react-i18next";
import { IQuestionnaireData } from ".";
import Icon, { IconTypes } from "../../components/Icon";
import step2_es from "./../../../assets/man_flow/step2_es.svg";
import step6_es from "./../../../assets/man_flow/step6_es.svg";
import step9_es from "./../../../assets/man_flow/step9_es.svg";
import step16_es from "./../../../assets/man_flow/step16_es.svg";

const queryParams = new URLSearchParams(window.location.search);
const lng = queryParams.get("lng");

export const man_flow_no_email: IQuestionnaireData[] = [
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_how_old_are_you",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_age_18_24",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_age_25_34",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_age_35_44",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_age_45_54",
      },
      {
        value: "q5",
        text: "main_male_questionnaire_age_55_64",
      },
      {
        value: "q6",
        text: "main_male_questionnaire_age_65_plus",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_train_pelvic_muscles",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_answer_yes_sure",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_answer_heard_only",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_answer_no",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_answer_what_are",
      },
    ],
  },
  {
    type: "information",
    badge: "",
    title: "main_male_questionnaire_ability_last_longer",
    description: "main_male_questionnaire_strong_muscles_key",
    image:
      lng === "es"
        ? step2_es
        : "https://res.cloudinary.com/the-coach/image/upload/v1701257281/Celadon-quiz-1_a692v5.svg",
  },
  {
    type: "information",
    badge: "",
    title: "main_male_questionnaire_pelvic_muscles_hard_on",
    description: "main_male_questionnaire_control_blood_pressure",
    image:
      "https://res.cloudinary.com/the-coach/image/upload/v1701257281/Celadon-quiz-2_fkjw1o.jpg",
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_rate_sexual_performance",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_very_dissatisfied",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_dissatisfied",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_ok_response",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_satisfied",
      },
      {
        value: "q5",
        text: "main_male_questionnaire_very_satisfied",
      },
    ],
  },
  {
    type: "information",
    badge: "",
    title: "main_male_questionnaire_intimate_life_upgrade",
    description: "",
    image:
      lng === "es"
        ? step6_es
        : "https://res.cloudinary.com/the-coach/image/upload/v1701241191/Celadon-quiz-3_syqzhy.svg",
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_average_sex_duration",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_less_than_2_min",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_between_2_10_min",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_between_10_20_min",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_20_min_or_more",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_finish_earlier",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_never",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_sometimes",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_most_of_the_time",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_dont_want_to_answer",
      },
    ],
  },
  {
    type: "information",
    badge: "",
    title: "main_male_questionnaire_coach_program_benefits",
    description: "main_male_questionnaire_coach_method_description",
    image:
      lng === "es"
        ? step9_es
        : "https://res.cloudinary.com/the-coach/image/upload/v1701241191/Celadon-quiz-4_fspp7o.svg",
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_full_hard_on",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_yes_no_problem",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_yes_want_stronger",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_sometimes",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_almost_never",
      },
      {
        value: "q5",
        text: "main_male_questionnaire_dont_want_to_answer",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "",
    title: "main_male_questionnaire_coach_program_success",
    description: "",
    Component: () => {
      const { t } = useTranslation();
      return (
        <div style={{ marginTop: "24px" }}>
          <img
            style={{
              width: "100%",
            }}
            src="https://res.cloudinary.com/the-coach/image/upload/v1701257281/Celadon-quiz-5_kgqj0y.svg"
            alt="text"
          />
          <span
            style={{
              display: "block",
              marginTop: "16px",
              fontFamily: "New York Small, Arial, sans-serif",
              fontSize: "18px",
              color: "#141d19",
              lineHeight: "22.5px",
            }}
          >
            {t("main_male_questionnaire_testimonial_text")}
          </span>
          <span
            style={{
              display: "block",
              marginTop: "16px",
              fontFamily: "New York Small, Arial, sans-serif",
              fontSize: "20px",
              color: "#141d19",
              lineHeight: "22.5px",
            }}
          >
            <b>{t("main_male_questionnaire_testimonial_author")}</b>
          </span>
        </div>
      );
    },
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_relationship_status",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_married",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_dating",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_single",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_dont_want_to_answer",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_sex_frequency",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_less_than_3",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_between_3_6",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_between_7_15",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_more_than_15",
      },
      {
        value: "q5",
        text: "main_male_questionnaire_dont_want_to_answer",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_sex_drive_decrease",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_dramatic_decrease",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_maybe_not_sure",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_no_want_more",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_no_feel_ok",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_taken_pills",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_take_lot_pills",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_use_pills_sometimes",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_no_dont",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_dont_want_to_answer",
      },
    ],
  },
  {
    type: "information",
    badge: "main_male_questionnaire_question_label_16",
    title: "main_male_questionnaire_coach_better_than_pills",
    description: "main_male_questionnaire_pill_free_benefits",
    image:
      lng === "es"
        ? step16_es
        : "https://res.cloudinary.com/the-coach/image/upload/v1701241191/Celadon-quiz-6_tnd5me.svg",
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_drink_alcohol",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_less_than_10",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_between_10_20",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_more_than_20",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_drink_rarely",
      },
      {
        value: "q5",
        text: "main_male_questionnaire_dont_drink_at_all",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_smoke",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_yes_smoke",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_no_dont",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_sometimes",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_follow_diet",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_eat_healthy",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_sometimes_junk",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_no_diet_attention",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_physical_activity",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_exercise_every_day",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_exercise_sometimes",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_low_physical_activity",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_no_exercise",
      },
    ],
  },
  {
    type: "single",
    badge: "",
    title: "main_male_questionnaire_rate_mood",
    description: "",
    questions: [
      {
        value: "q1",
        text: "main_male_questionnaire_terrible_stress",
      },
      {
        value: "q2",
        text: "main_male_questionnaire_average_stress",
      },
      {
        value: "q3",
        text: "main_male_questionnaire_no_stress",
      },
      {
        value: "q4",
        text: "main_male_questionnaire_hard_to_say",
      },
    ],
  },
  {
    type: "information-custom",
    badge: "",
    title: "main_male_questionnaire_you_are_in_good_hands",
    description: "",
    Component: () => {
      const { t } = useTranslation();
      return (
        <div style={{ marginTop: "28px" }}>
          <div style={{ display: "flex" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.BICEPS} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_coach_experts")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "28px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.BICEPS} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_coach_science_based")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "28px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.BICEPS} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_coach_helped_thousands")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "28px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.BICEPS} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_coach_personalized")}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    type: "information-custom",
    badge: "",
    title: "main_male_questionnaire_why_program_unique",
    description: "",
    Component: () => {
      const { t } = useTranslation();

      return (
        <div style={{ marginTop: "28px" }}>
          <div style={{ display: "flex" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.CHECK} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_five_minutes_day")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.CHECK} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_train_anywhere")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.CHECK} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_nobody_notices")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.CHECK} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_program_evolves")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.CHECK} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_theory_practice")}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "24px" }}>
            <span style={{ minWidth: "18px" }}>
              <Icon type={IconTypes.CHECK} size={18} />
            </span>
            <span
              style={{
                marginLeft: "16px",
                fontFamily: "New York Small, Arial, sans-serif",
                fontSize: "18px",
                color: "#141d19",
                lineHeight: "22.5px",
              }}
            >
              {t("main_male_questionnaire_first_results")}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    type: "analyze",
    badge: "",
    title: "",
    description: "",
    Component: () => <></>,
  },
];
