import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Presale from "../../components/Presale";
import PresaleForm from "../../components/PresaleForm";

enum modalEnum {
  PRESALE = "presale",
  PRESALE_FORM = "presaleForm",
}

function Packs() {
  const [paymentModalStep, setPaymentModalStep] = useState<modalEnum>(
    modalEnum.PRESALE
  );

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {paymentModalStep === modalEnum.PRESALE && (
        <Presale
          onAdd={() => {
            setPaymentModalStep(modalEnum.PRESALE_FORM);
            window.scrollTo(0, 0);
          }}
          onContinue={() => navigate("/payment-success")}
        />
      )}
      {paymentModalStep === modalEnum.PRESALE_FORM && (
        <PresaleForm onClose={() => setPaymentModalStep(modalEnum.PRESALE)} />
      )}
    </>
  );
}

export default Packs;
