import { FC } from "react";
import cls from "classnames";
import SubTitle from "../SubTitle";

import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import { useTranslation } from "react-i18next";

const MoneyBack: FC = () => {
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;
  const { t } = useTranslation();

  return (
    <section className={cls(styles.moneyBeck, { [styles.isWhite]: isWhite })}>
      <div className={styles.header}>
        <img
          alt="money back"
          src="https://join.the.coach/img/content/paywall/money-back.png"
          width="60px"
          height="71px"
        />
        <SubTitle text={t("paywall_money_back_guarantee")} />
      </div>
      <p>{t("paywall_money_back_text")}</p>
    </section>
  );
};

export default MoneyBack;
