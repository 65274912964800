import { InputHTMLAttributes } from "react";
import Select, { SingleValue } from "react-select";

import styles from "./style.module.scss";
import { countries } from "./contries";

type Tprorps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  onReset: () => void;
  errorsMessage: string;
  errors: boolean;
  name: string;
};

const Input = ({
  label,
  onReset,
  errorsMessage,
  errors,
  name,
  ...inputProps
}: Tprorps) => {
  const onCountryChange = (
    value: SingleValue<{
      value: string;
      label: string;
    }>
  ) => {
    const { onChange } = inputProps;
    //@ts-ignore
    onChange(value ? value.value : "");
  };

  const getInputByName = (name: string) => {
    switch (name) {
      case "phone":
        return (
          <div className={styles.phoneContainer}>
            <input
              {...inputProps}
              className={styles.input}
              aria-invalid={errors ? "true" : "false"}
            />
            <span className={styles.plus}>+</span>
          </div>
        );
      case "country":
        return (
          <Select
            isClearable
            options={countries}
            className="country-select-container"
            classNamePrefix="country-select"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            onChange={onCountryChange}
          />
        );
      default:
        return (
          <input
            {...inputProps}
            className={styles.input}
            aria-invalid={errors ? "true" : "false"}
          />
        );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <span className={styles.label}>{label}</span>
          {getInputByName(name)}
        </div>
        <button className={styles.icon} onMouseDown={onReset}>
          <span>&#x2715;</span>
        </button>
      </div>
      {!!errorsMessage && <p className={styles.error}>{errorsMessage}</p>}
    </>
  );
};

export default Input;
