import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import cls from "classnames";
import { useTranslation } from "react-i18next";

interface IQuestionnaireAnalize {
  onNextQuestion: (answer: string) => void;
}

const QuestionnaireAnalize: FC<IQuestionnaireAnalize> = ({
  onNextQuestion,
}) => {
  const { t } = useTranslation();
  const [time, setTime] = useState(0);

  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  useEffect(() => {
    const fn = () => {
      setTime((prev) => prev + 1);
    };

    const interval = setInterval(fn, 80);

    return () => {
      clearInterval(interval);
    };
  }, [setTime]);

  if (time === 100) {
    onNextQuestion("Analized");
  }

  return (
    <div className={cls(styles.wrapper, { [styles.isWhite]: isWhite })}>
      <div className={styles.title}>
        {t("main_male_questionnaire_analyzing_answers")}
      </div>
      <div className={styles.loadingWrapper}>
        <div className={styles.loading}>
          <div className={styles.percents}>{time}%</div>
          <svg viewBox="0 0 350 350" className={styles.circularProgress}>
            <circle className={styles.bg}></circle>
            <circle className={styles.fg}></circle>
          </svg>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <span className={styles.icon}>
            {time > 25 && <Icon type={IconTypes.CHECK} size={24} />}
          </span>
          <span className={styles.text}>
            {t("main_male_questionnaire_intimate_health_summary")}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.icon}>
            {time > 50 && <Icon type={IconTypes.CHECK} size={24} />}
          </span>
          <span className={styles.text}>
            {t("main_male_questionnaire_lifestyle_analysis")}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.icon}>
            {time > 75 && <Icon type={IconTypes.CHECK} size={24} />}
          </span>
          <span className={styles.text}>
            {t("main_male_questionnaire_adapting_needs")}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.icon}>
            {time > 95 && <Icon type={IconTypes.CHECK} size={24} />}
          </span>
          <span className={styles.text}>
            {t("main_male_questionnaire_creating_plan")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireAnalize;
