import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { lazy, Suspense } from "react";
import useLazyGoogleTag from "./app/hooks/useLazyGoogleTag";

import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;

const FacebookPixel = lazy(
  () => import(/* webpackChunkName: "FacebookPixel" */ "./FacebookPixel")
);

const Sentry = lazy(() => import(/* webpackChunkName: "Sentry" */ "./Sentry"));

const Plans = lazy(
  () => import(/* webpackChunkName: "Plans" */ "./app/pages/Plans")
);

const Packs = lazy(
  () => import(/* webpackChunkName: "Packs" */ "./app/pages/Packs")
);

const PaymentSuccess = lazy(
  () =>
    import(
      /* webpackChunkName: "PaymentSuccess" */ "./app/pages/PaymentSuccess"
    )
);

const ChatBot = lazy(
  () => import(/* webpackChunkName: "Packs" */ "./app/pages/ChatBot")
);

const Questionnaire = lazy(
  () => import(/* webpackChunkName: "Packs" */ "./app/pages/Questionnaire")
);

// const Fullstory = lazy(
//   () => import(/* webpackChunkName: "Fullstory" */ "./Fullstory")
// );

function App() {
  useLazyGoogleTag();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <>Root -2</>,
    },
    {
      path: "/plans",
      element: (
        <Suspense fallback={<></>}>
          <IntercomProvider appId={INTERCOM_APP_ID as string}>
            <Plans />
          </IntercomProvider>
        </Suspense>
      ),
    },
    {
      path: "/payment-success",
      element: (
        <Suspense fallback={<></>}>
          <PaymentSuccess />
        </Suspense>
      ),
    },
    {
      path: "/packs",
      element: (
        <Suspense fallback={<></>}>
          <Packs />
        </Suspense>
      ),
    },
    {
      path: "/chat-bot",
      element: (
        <Suspense fallback={<></>}>
          <ChatBot />
        </Suspense>
      ),
    },
    {
      path: "/chat-bot/:bot_id",
      element: (
        <Suspense fallback={<></>}>
          <ChatBot />
        </Suspense>
      ),
    },
    {
      path: "/questionnaire",
      element: (
        <Suspense fallback={<></>}>
          <Questionnaire />
        </Suspense>
      ),
    },
  ]);

  return (
    <>
      {window.location.pathname !== "/chat-bot" && (
        <>
          <Sentry />
          <FacebookPixel />
        </>
      )}

      <RouterProvider router={router} />
      {/* <Fullstory /> */}
    </>
  );
}

export default App;
