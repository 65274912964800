import { FC } from "react";
import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  IQuestionnaireData,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import Icon, { IconTypes } from "../Icon";
import clx from "classnames";

interface IQuestionnaireSingleSquare {
  question: IQuestionnaireData;
  onNextQuestion: (answer: string) => void;
}

const QuestionnaireSingleSquare: FC<IQuestionnaireSingleSquare> = ({
  question: { questions },
  onNextQuestion,
}) => {
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  if (!questions) return null;

  return (
    <div className={clx(styles.wrapper, { [styles.isWhite]: isWhite })}>
      {questions.map((question) => (
        <div
          className={styles.question}
          onClick={() => onNextQuestion(question.value)}
        >
          <div
            className={styles.img}
            style={{
              backgroundImage: `url(${question.img})`,
            }}
          ></div>
          <div className={styles.footer}>
            <div className={styles.text}>{question.text}</div>
            <div className={styles.icon}>
              <Icon type={IconTypes.ARROW_LEFT} size={10} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionnaireSingleSquare;
