import { FC } from "react";

import styles from "./styles.module.scss";
import classNames from "classnames";
import Spinner from "../Spinner";

export type ButtonVariants = "primary" | "secondary";

interface IProps {
  handleClick: () => void;
  text?: string;
  buttonVariants: ButtonVariants;
  isLoaded?: boolean;
}

const Button: FC<IProps> = ({
  handleClick,
  text,
  buttonVariants,
  isLoaded,
}) => (
  <button
    className={classNames({
      [styles.primary]: buttonVariants === "primary",
      [styles.secondary]: buttonVariants === "secondary",
      [styles.disabled]: isLoaded,
    })}
    onClick={handleClick}
  >
    {!isLoaded ? text : <Spinner small />}
  </button>
);

export default Button;
