import { FC, useState } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";
import { validateEmail } from "../../helpers/cardValidation";
import { api, ApiPaths } from "../../api";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

interface IQuestionnaireEmail {
  onNextQuestion: (answer: string) => void;
}

const QuestionnaireEmail: FC<IQuestionnaireEmail> = ({ onNextQuestion }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setIsError(!validateEmail(value));
  };

  const onSubmit = async () => {
    if (isError) {
      setIsShowError(true);
      return;
    }

    setLoading(true);

    const url = window.location.href;

    const response = await api.post(
      ApiPaths.QUESTIONNAIRE_SUBMIT + "?onboarding_email=" + email,
      {
        email: email,
        url: url,
      },
      {
        headers: {
          Referer: url,
        },
      }
    );

    if (response.status === 200) {
      localStorage.setItem("payment_email", email);
      onNextQuestion("Submited");
    } else {
      alert("Something went wrong");
    }

    setLoading(false);
  };

  const error = isShowError && isError;

  return (
    <div className={styles.wrapper}>
      {loading && <Loading />}
      <div className={styles.container}>
        <div className={styles.box}>
          <input
            onChange={onEmailChange}
            placeholder={
              t("main_male_questionnaire_email_placeholder") as string
            }
            className={styles.input}
            aria-invalid={error ? "true" : "false"}
            type="email"
          />
        </div>
      </div>
      {error && (
        <p className={styles.error}>
          {t("main_male_questionnaire_valid_mail")}
        </p>
      )}
      <div className={styles.privacy}>
        <Icon type={IconTypes.LOCK} />
        <span>
          {t("main_male_questionnaire_privacy_policy_1")}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://vamapps.com/privacy_policy"
          >
            {t("main_male_questionnaire_privacy_policy_2")}
          </a>{" "}
          {t("main_male_questionnaire_privacy_policy_3")}
        </span>
      </div>
      <div
        className={styles.btn}
        onClick={() => email && onSubmit()}
        data-disabled={!email}
      >
        {t("main_male_questionnaire_continue")}
      </div>
    </div>
  );
};

export default QuestionnaireEmail;
