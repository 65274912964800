import styles from "./styles.module.scss";

import logo from "./../../../assets/logos/Logo_img.png";
import banner from "./../../../assets/banner.png";
import WhatsImportant from "./WhatsImportant";

import image from "./../../../assets/importants/img.jpeg";
import image1 from "./../../../assets/importants/img-1.jpeg";
import image2 from "./../../../assets/importants/img-2.jpeg";
import image3 from "./../../../assets/importants/img-3.jpeg";

import benefitIcon from "./../../../assets/benefits/icon.svg";
import benefitIcon1 from "./../../../assets/benefits/icon-1.svg";
import benefitIcon2 from "./../../../assets/benefits/icon-2.svg";
import benefitIcon3 from "./../../../assets/benefits/icon-3.svg";
import benefitIcon4 from "./../../../assets/benefits/icon-4.svg";
import benefitIcon5 from "./../../../assets/benefits/icon-5.svg";

import { Fragment } from "react/jsx-runtime";
import Formula from "./Formula";
import BenefitItem from "./BenefitItem";
import Slider from "react-slick";
import SlideItemBlock from "./slideItemBlock";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MouseEventHandler } from "react";

interface Iprops {
  onAdd: MouseEventHandler;
  onContinue: MouseEventHandler;
}

interface IImportants {
  img: string;
  title: string;
  describtion: string;
  direction: "left" | "right";
}

const importants: IImportants[] = [
  {
    title: "Double the effectiveness of your program",
    img: image,
    describtion:
      "Users who take supplements alongside following their program report getting first noticeable results 2x faster",
    direction: "left",
  },
  {
    title: "A Special Formula Tailored to Your Needs",
    img: image1,
    describtion:
      "Based on your survey results, we have designed a special formula to address your specific problem, improving stamina, control, and overall men's health",
    direction: "right",
  },
  {
    title: "Get micro-elements essential for male health",
    img: image2,
    describtion:
      "Not all nutrients essential for men’s health can be obtained from regular food. According to research, men often lack magnesium, B6, and other vital elements.",
    direction: "left",
  },
  {
    title: "Your Health is Our Priority",
    img: image3,
    describtion:
      "Our formula has been developed by top experts in men’s health. We adhere to a drug-free approach, using only the most effective natural ingredients.",
    direction: "right",
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerMode: true,

  responsive: [
    {
      breakpoint: 720,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
      },
    },
  ],
};

const benefits = [
  {
    title: "Drugs \nFree",
    icon: benefitIcon,
  },
  {
    title: "Only natural \ningredients",
    icon: benefitIcon1,
  },
  {
    title: "Science \nbacked",
    icon: benefitIcon2,
  },
  {
    title: "Developed by \nexperts",
    icon: benefitIcon3,
  },
  {
    title: "Personalized \nFormula",
    icon: benefitIcon4,
  },
  {
    title: "Free \nDelivery",
    icon: benefitIcon5,
  },
];

const reviews = [
  {
    name: "Andrew",
    raiting: 5,
    describtion:
      "Honestly, the supplements and program together worked better than I expected. I’ve struggled with PE for a while, and now I’m finally seeing some steady improvements. It’s been a relief.",
  },
  {
    name: "L_L.Mark",
    raiting: 5,
    describtion:
      "Have been taking PE supplements for 2 months now, nothing ever worked better than it. As a bonus, you feel calmer and happier.",
  },
  {
    name: "Alex190922",
    raiting: 5,
    describtion:
      "The Coach has brought everything I need into one place. I just follow the program and see the results. It's rare that I actually feel like I’m getting my money’s worth, but this time, I do!",
  },
  {
    name: "Chris",
    raiting: 5,
    describtion:
      "Took 02 supplements for a month, got a big boost in sexual desire. It’s probably the l-citrulline’s effect. Anyways, it simply works.",
  },
  {
    name: "Meit8_14",
    raiting: 5,
    describtion:
      "I regret starting the program without the supplements. In the first month, I could have achieved so much more. Once I added them, my progress noticeably sped up. Don’t make the same mistake I did!",
  },
];

const Presale = ({ onAdd, onContinue }: Iprops) => {
  return (
    <>
      <div className={styles.content}>
        <section>
          <img src={logo} alt="logo" className={styles.logo} />
        </section>
        <section>
          <h3 className={styles.offerDescriptionTitle}>
            Special Offer for New Users
          </h3>
          <span className={styles.divider} />
          <h2 className={styles.offerDescriptionSubTitle}>
            Boost your program’s results{" "}
          </h2>
          <p className={styles.offerDescriptionDescription}>
            With the scientifically-developed supplements for men
          </p>
        </section>
        <section className={styles.banner}>
          <img src={banner} alt="banner" className={styles.bannerImg} />
        </section>
        <section className={styles.whyItsImportant}>
          <h3 className={styles.whyItsImportantTitle}>
            Why it’s important to add supplements to program
          </h3>
          {importants.map((item, index) => (
            <Fragment key={index}>
              <WhatsImportant {...item} />
            </Fragment>
          ))}
        </section>
        <section className={styles.formulaSection + " " + styles.section}>
          <Formula />
        </section>
        <section className={styles.benefitSection + " " + styles.section}>
          {benefits.map((item, index) => (
            <Fragment key={index}>
              <BenefitItem {...item} />
            </Fragment>
          ))}
        </section>
        <section className={styles.carousel}>
          <h3 className={styles.whyItsImportantTitle}>
            {"Real Members \nReal Reviews"}
          </h3>
          <Slider {...settings}>
            {reviews.map((item, index) => (
              <Fragment key={index}>
                <SlideItemBlock {...item} />
              </Fragment>
            ))}
          </Slider>
        </section>
      </div>
      <footer className={styles.footer}>
        <section className={styles.section}>
          <span className={styles.footerDivivder} />
          <h3 className={styles.footerTitle}>
            {"Sexual Health \nImprovement \nStarts Here"}{" "}
          </h3>
          <span className={styles.footerDivivder} />
          <button className={styles.primery} onClick={onAdd}>
            ADD TO MY PROGRAM
          </button>
          <button className={styles.secondary} onClick={onContinue}>
            SKIP THIS AND CONTINUE ENROLLMENT
          </button>
        </section>
      </footer>
    </>
  );
};

export default Presale;
