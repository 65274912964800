import { UAParser } from "ua-parser-js";
import { api, ApiPaths } from "../api";
import { useCallback } from "react";

const useBackendLog = () => {
  const onBackendLog = useCallback(async (obj: { [k: string]: unknown }) => {
    try {
      const parser = new UAParser();
      await api.post(ApiPaths.BACKEND_LOG, {
        ...obj,
        ...parser.getResult(),
        url: window.location.href,
      });
    } catch {
      console.log("Failed to log");
    }
  }, []);

  return { onBackendLog };
};

export default useBackendLog;
