import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";
import { CANCEL_STEPS, IUserData } from "../../pages/Cancel";
import { api, ApiPaths } from "../../api";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";

interface ICancelStepAccount {
  onNextStep: (step: CANCEL_STEPS) => void;
  setUserData: Dispatch<SetStateAction<IUserData | undefined>>;
  userData: IUserData | undefined;
  userUid?: string;
  user_id?: string;
}

const CancelStepAccount: FC<ICancelStepAccount> = ({
  onNextStep,
  setUserData,
  userData,
  userUid,
  user_id,
}) => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userDataLoading, setUaserDataLoading] = useState(false);

  const onOpenPopup = () => {
    setIsPopupOpen(true);

    amplitude.track({
      event_type: "Cancel_SubDetails_PopUp",
      user_id: user_id,
    });
  };

  const onClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    (async () => {
      setUaserDataLoading(true);
      if (userUid) {
        try {
          const response: { status: number; data: IUserData } = await api.post(
            ApiPaths.USER_SUBSCRIPTION_DATA,
            {
              uid: userUid,
            }
          );

          if (response.status === 200) {
            setUserData(response.data);
          }
        } catch (e: any) {
          console.log(e);
        }
      }
      setUaserDataLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUid]);

  return (
    <>
      {isPopupOpen && (
        <div className={styles.popup}>
          <div className={styles.content}>
            <div className={styles.close} onClick={onClosePopup}>
              <Icon type={IconTypes.CROSS} size={16} />
            </div>
            <div className={styles.text}>{t("cancel_flow_confirm_cancel")}</div>
            <button className={styles.button} onClick={onClosePopup}>
              {t("cancel_flow_cancel_no")}
            </button>
            <button
              className={styles.button_secondary}
              onClick={() => onNextStep(CANCEL_STEPS.PROMO)}
            >
              {t("cancel_flow_cancel_yes")}
            </button>
          </div>
        </div>
      )}

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>{t("cancel_flow_your_account")}</div>
          <div className={styles.image}></div>
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.label}>
              {t("cancel_flow_personalized_plan")}
            </div>
          </div>

          {userDataLoading && (
            <>
              <div className={styles.divider}></div>
              <div className={styles.row}>
                <div className={styles.label}>{t("cancel_flow_loading")}</div>
                <div className={styles.value}></div>
              </div>
            </>
          )}

          {userData && (
            <>
              <div className={styles.divider}></div>
              <div className={styles.row}>
                <div className={styles.label}>{t("cancel_flow_email")}</div>
                <div className={styles.value}>{userData.email}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>{t("cancel_flow_plan_type")}</div>
                <div className={styles.value}>{userData.plan_type}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>
                  {t("cancel_flow_intro_offer")}
                </div>
                <div className={styles.value}>
                  {userData.subscription_status}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>
                  {t("cancel_flow_supplements_discount")}
                </div>
                <div className={styles.value}>
                  {userData.supplements_discount}
                </div>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.row}>
                <div className={styles.label}>
                  {t("cancel_flow_billing_period")}
                </div>
                <div className={styles.value}>{userData.billing_period}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>
                  {t("cancel_flow_subscription_status")}
                </div>
                <div className={styles.value}>
                  {userData.subscription_status}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>
                  {t("cancel_flow_paid_until")}
                </div>
                <div className={styles.value}>{userData.paid_until}</div>
              </div>
              {userData.subscription_status !== "Canceled" && (
                <>
                  <div className={styles.divider}></div>
                  <div className={styles.row}>
                    <div className={styles.autoRenewal}>
                      <span onClick={onOpenPopup}>
                        {t("cancel_flow_auto_renewal")}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CancelStepAccount;
