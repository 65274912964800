import { FC, useEffect } from "react";
import braintree, { Client } from "braintree-web";
import styles from "./styles.module.scss";
import * as Sentry from "@sentry/react";
import { sentryLog } from "../../helpers/sentryLog";
import useBackendLog from "../../hooks/useBackendLog";
import { useTranslation } from "react-i18next";

interface IProps {
  braintreeClientInstance: Client;
  onPaymentMethodReceived: (nonce: string) => void;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const BraintreePaypal: FC<IProps> = ({
  braintreeClientInstance,
  onPaymentMethodReceived,
  setIsPaymentProgress,
}) => {
  const { t } = useTranslation();
  const { onBackendLog } = useBackendLog();

  useEffect(() => {
    const renderPayPalButton = () => {
      if (braintreeClientInstance) {
        braintree.paypalCheckout.create(
          {
            client: braintreeClientInstance,
          },
          function (paypalCheckoutErr, paypalCheckoutInstance) {
            if (paypalCheckoutInstance) {
              paypalCheckoutInstance.loadPayPalSDK(
                {
                  vault: true,
                },
                function () {
                  window.paypal
                    .Buttons({
                      style: {
                        //@ts-ignore
                        size: "responsive",
                        //@ts-ignore
                        color: "gold",
                        //@ts-ignore
                        shape: "rect",
                        //@ts-ignore
                        label: "paypal",
                        height: 55,
                      },
                      //@ts-ignore
                      fundingSource: paypal.FUNDING.PAYPAL,

                      createBillingAgreement: function () {
                        onBackendLog({
                          type: "payment_form_loaded",
                        });
                        setIsPaymentProgress(true);
                        return paypalCheckoutInstance.createPayment({
                          //@ts-ignore
                          flow: "vault",
                        });
                      },

                      //@ts-ignore
                      onApprove: function (data, actions) {
                        return paypalCheckoutInstance.tokenizePayment(
                          data,
                          function (err, payload) {
                            onPaymentMethodReceived(payload.nonce);
                          }
                        );
                      },

                      onCancel: function (data) {
                        sentryLog("PayPal payment canceled by user", data);
                        setIsPaymentProgress(false);
                      },

                      onError: function (error) {
                        console.error("PayPal error", error);
                        Sentry.captureException(error);
                      },
                    })
                    .render("#paypal-button");
                }
              );
            }
          }
        );
      }
    };

    renderPayPalButton();
  }, []); // eslint-disable-line

  return (
    <div className={styles.wrapper}>
      <div id="paypal-button"></div>
      <span>{t("paywall_paypal_desc")}</span>
    </div>
  );
};

export default BraintreePaypal;
