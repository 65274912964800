import { FC } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
// import Icon, { IconTypes } from "../Icon";

interface ICancelStepInstruction {}

const CancelStepInstruction: FC<ICancelStepInstruction> = () => {
  // const onProfileClick = () => {
  //   window.location.href = "thecoach://index";
  // };
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.wrapper}>
        {/* <div className={styles.profile} onClick={onProfileClick}>
          <Icon type={IconTypes.ARROW_LEFT} size={12} />
          <div className={styles.text}>Profile</div>
        </div> */}
        <div className={styles.title}>
          {t("cancel_flow_manage_subscription")}
        </div>
        <div className={styles.content}>
          <span className={styles.subtitle}>
            {t("cancel_flow_app_store_info")}
          </span>
          <br />
          <br />
          {t("cancel_flow_app_store_step_1")}
          <br />
          <br />
          {t("cancel_flow_app_store_step_2")}
          <br />
          <br />
          {t("cancel_flow_app_store_step_3")}
          <br />
          <br />
          {t("cancel_flow_app_store_step_4")}
        </div>
        <div className={styles.content}>
          <span className={styles.subtitle}>
            {t("cancel_flow_play_store_info")}
          </span>
          <br />
          <br />
          {t("cancel_flow_play_store_step_1")}
          <br /> <br />
          {t("cancel_flow_play_store_step_2")}
          <br />
          <br />
          {t("cancel_flow_play_store_step_3")}
          <br />
          <br />
          {t("cancel_flow_play_store_step_4")}
          <br />
          <br />
          {t("cancel_flow_play_store_step_5")}
        </div>
      </div>
    </>
  );
};

export default CancelStepInstruction;
