import { FC } from "react";
import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  IQuestionnaireData,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import cls from "classnames";
import { useTranslation } from "react-i18next";

interface IQuestionnaireInformation {
  question: IQuestionnaireData;
  onNextQuestion: (answer: string) => void;
}

const QuestionnaireInformation: FC<IQuestionnaireInformation> = ({
  onNextQuestion,
  question: { image },
}) => {
  const { t } = useTranslation();
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={image} alt="" />

      <div className={cls(styles.background, { [styles.isWhite]: isWhite })}>
        <div className={styles.btn} onClick={() => onNextQuestion("Got it")}>
          {t("main_male_questionnaire_got_it")}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireInformation;
