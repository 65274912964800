import { FC } from "react";
import Icon, { IconTypes } from "../Icon";
import banner from "./../../../assets/specialOfferBanner.png";

import styles from "./styles.module.scss";
import Button from "../Button";

interface IProps {
  onClose: () => void;
}

const SpecialOfferModal: FC<IProps> = ({ onClose }) => {
  return (
    <div className={styles.wrapper}>
      <span onClick={onClose} className={styles.close}>
        <Icon type={IconTypes.CROSS} size={18} />
      </span>
      <div className={styles.title}>DID YOU KNOW</div>
      <div className={styles.description}>
        <b>68%</b> of users who began <b>The Coach Men's Health Program </b>
        achieved their goals within <b>the first month</b>*
      </div>
      <img className={styles.banner} src={banner} alt="stast" />
      <div className={styles.note}>
        * Based on the data and feedback of The Coach app users
      </div>
      <div className={styles.block}>
        We aim for your success, hence we're providing an <b>extra discount</b>{" "}
        on <b>your Program</b>
      </div>
      <div className={styles.btn}>
        <Button
          buttonVariants="secondary"
          handleClick={onClose}
          text="GOT IT"
        />
      </div>
    </div>
  );
};

export default SpecialOfferModal;
