import { FC } from "react";

import styles from "./styles.module.scss";
import {
  getQuestionnaireTheme,
  QUESTIONNAIRE_THEME,
} from "../../pages/Questionnaire";
import cls from "classnames";
import { useTranslation } from "react-i18next";

interface IProps {
  totalCrossed: string;
  total: string;
  planDuration: string;
  offer?: string;
  pricePerDay?: string;
  onClick: () => void;
  isSelected: boolean;
}

const DiscountPerMonths: FC<IProps> = ({
  totalCrossed,
  total,
  offer,
  planDuration,
  pricePerDay,
  onClick,
  isSelected,
}) => {
  const { t } = useTranslation();
  const theme = getQuestionnaireTheme();
  const isWhite = QUESTIONNAIRE_THEME.WHITE === theme;

  return (
    <div
      className={cls(
        isSelected ? styles.selectedDiscountBlock : styles.discountBlock,
        { [styles.isWhite]: isWhite }
      )}
      onClick={onClick}
    >
      <div className={styles.totalBlock}>
        <p className={styles.planDuration}>{planDuration}</p>
        <div className={styles.total}>
          <span>Total:</span>
          <span className={styles.totalCrossed}>{totalCrossed}</span>
          <span className={styles.total}>{total}</span>
        </div>
      </div>

      <div className={styles.perDayBlock}>
        {offer ? (
          <div className={styles.save}>
            <span className={styles.discountPercent}>{offer}</span>
            <span />
          </div>
        ) : null}

        <p className={styles.pricePerDay}>
          <span>{pricePerDay}</span>
          <span>/{t("paywall_payment_day")}</span>
        </p>
      </div>
    </div>
  );
};

export default DiscountPerMonths;
