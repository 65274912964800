import Layout, { PageBG } from "../../components/Layout";
import { FC, useEffect, useState } from "react";

import { ReactComponent as CoachBackGround } from "./../../../assets/svg/coachBackground.svg";
import { ReactComponent as Exclamation } from "./../../../assets/svg/exclamation.svg";
import Title from "../../components/Title";
import Header from "../../components/Header";
import Divider from "../../components/Divider";
import Icon, { IconTypes } from "../../components/Icon";
import Menu from "../../components/Menu";

import styles from "./styles.module.scss";
import useSendEmail from "../../hooks/useSendEmail";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const PaymentSuccess: FC = () => {
  const { t } = useTranslation();
  const [isSended, setIsSended] = useState<boolean>(false);
  const [meunOpen, setMenuOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [braintreeUserId, setBraintreeUserId] = useState<string>("");
  const [deeplink, setDeeplink] = useState<string>("");

  const [error, setError] = useState<string | null>(null);
  const { loading, sendEmail } = useSendEmail();

  useEffect(() => {
    const { payment_email, braintree_user_id, deeplink } = {
      payment_email: localStorage.getItem("payment_email"),
      braintree_user_id: localStorage.getItem("braintree_user_id"),
      deeplink: localStorage.getItem("deeplink"),
    };

    if (deeplink) setDeeplink(deeplink);
    if (braintree_user_id) setBraintreeUserId(braintree_user_id);
    if (payment_email) setEmail(payment_email);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSend = () => {
    sendEmail(
      deeplink,
      email,
      braintreeUserId,
      () => {
        setIsSended(true);
      },
      () => {
        setError("Something went wrong");
      }
    );

    ReactGA.gtag("event", "email_send_button_clicked", {});
  };

  const handleMenuToggle = () => setMenuOpen(!meunOpen);

  const onDownlaodClick = () => {
    sendDownloadEvent();
    window.location.href = deeplink;
  };

  const sendDownloadEvent = () => {
    ReactGA.gtag("event", "download_app_tap", {});
  };

  return (
    <Layout pageBG={PageBG.PAYMENT_SUCCESS}>
      <Header toggler={handleMenuToggle} isPaymentSuccess />

      <Menu toggler={handleMenuToggle} isOpen={meunOpen} isPaymentMenu />

      <Divider top={7} isMenu />
      <div className={styles.paymentSuccessWrapper}>
        <CoachBackGround className={styles.backgroundSvg} />
        <div className={styles.paymentSuccessContent}>
          <span className={styles.checked}>
            <Icon type={IconTypes.CHECK} color="green" size={20} />
          </span>
          <Title
            fontSize={40}
            text={t("cancel_flow_congratulations")}
            color="#dfe0df"
          />
          <p className={styles.enterYourEmail}>{t("paywall_inst_desc")}</p>

          <div className={styles.emailSend}>
            <label>
              <span>{t("paywall_enter_email")}</span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <input
              type="submit"
              value={
                !isSended
                  ? t("paywall_success_send_btn") || ""
                  : t("paywall_success_done_btn") || ""
              }
              className={!isSended ? styles.sendBtn : styles.doneBtn}
              onClick={onSend}
              disabled={loading}
            />
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </div>

        <div
          className={
            isSended ? styles.downloadBlock : styles.disabledDownloadBlock
          }
        >
          <p className={styles.downloadLink}>
            {t("paywall_success_sub_title_1")} &nbsp;
            <a href={deeplink} onClick={sendDownloadEvent}>
              {t("paywall_success_sub_title_2")}
            </a>
            &nbsp; {t("paywall_success_sub_title_3")}
          </p>
          {isSended && (
            <div className={styles.downloadTooltip}>
              <div className={styles.exp_icon}>
                <Exclamation />
              </div>
              <p>
                <span>{t("paywall_success_important_msg_1")}</span>{" "}
                {t("paywall_success_important_msg_2")}
              </p>
            </div>
          )}
          <button onClick={onDownlaodClick}>{t("paywall_success_btn")}</button>
        </div>
        <p className={styles.support}>
          {t("paywall_success_payment_support")} &nbsp;
          <a href="mailto:support@the.coach">support@the.coach</a>
        </p>
      </div>
    </Layout>
  );
};

export default PaymentSuccess;
